import Box from '@mui/material/Box';
import PartnerCarouselCard from './PartnerCarouselCard';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

export default({model, partners, clickHandler}) => {
    const cardClicked = (id) => {
        clickHandler?.(id);
    }
    if(!partners) {
        return(<Box>Empty...</Box>);
    }
    return(
        <Box sx={{ flexGrow: 1 }}>
            <Swiper spaceBetween={12} slidesPerView={8}>
            {partners.map((_, index) => (
                <SwiperSlide key={_.id}>
                    <PartnerCarouselCard model={model} item={_} clickHandler={cardClicked} />
                </SwiperSlide>
            ))}
            </Swiper>
        </Box>
    );
}