import axios from 'axios';
import { config as apiConfig, selectedProfile } from '../config/config';

const axiosInstancePublicApi = axios.create({
    baseURL: apiConfig[selectedProfile].PUBLIC_API_BASE_URL
});

const axiosInstanceAuthApi = axios.create({
    baseURL: apiConfig[selectedProfile].AUTH_API_BASE_URL
});

function getBasicHeaders(addClientId = false, token = null) {
    const headers = {
        "Content-Type": "application/json"
    };
    if(addClientId) {
        headers["X-Request-Client-ID"] = apiConfig[selectedProfile].OAUTH_CLIENT_ID;
    }
    if(token) {
        headers["Authorization"] = "Bearer "+token;
    }
    return headers;
}

function getBasicOptions(addClientId = false, token = null) {
    return {
        headers: getBasicHeaders(addClientId, token),
        validateStatus: false
    }
}

//API requests
//Auth API
async function login(email, password) {
    const data = {email: email, password : password, firebaseToken: "xxx", region: "hu", platformType: "ANDROID", referralCode: null};
    return await axiosInstanceAuthApi.post("/user/signin", JSON.stringify(data), getBasicOptions(true));
}

async function loginFacebook(accessToken) {
    const data = {accessToken: accessToken, email: null, firebaseToken: "xxx", region: "hu", platformType: "WEB", referralCode: null};
    return await axiosInstanceAuthApi.post("/user/facebook", JSON.stringify(data), getBasicOptions(true));
}

async function register(email, password, region) {
    const data = {email: email, password : password, firebaseToken: "xxx", region: region, platformType: "WEB", referralCode: null};
    return await axiosInstanceAuthApi.post("/user/signup", JSON.stringify(data), getBasicOptions(true));
}

async function forgetPassword(email, lang) {
    const data = {email, lang};
    return await axiosInstanceAuthApi.post("/user/request-password", JSON.stringify(data), getBasicOptions(true));
}

//Public API
async function getRegions(token) {
    return await axiosInstancePublicApi.get("/user/regions", getBasicOptions(false, token));
}

async function getProfile(token) {
    return await axiosInstancePublicApi.get("/user/", getBasicOptions(false, token));
}

async function getUserFriends(token) {
    return await axiosInstancePublicApi.get("/user/friends", getBasicOptions(false, token));
}

async function getUserSettings(token) {
    return await axiosInstancePublicApi.get("/user/settings", getBasicOptions(false, token));
}

async function saveUserSettings(token, data) {
    return await axiosInstancePublicApi.post("/user/settings", JSON.stringify(data), getBasicOptions(true, token));
}

async function changePassword(token, oldPassword, newPassword) {
    const data = {oldPassword, newPassword};
    return await axiosInstancePublicApi.post("/user/auth/change-password", JSON.stringify(data), getBasicOptions(true, token));
}

async function getTransactions(token) {
    return await axiosInstancePublicApi.get("/user/transactions", getBasicOptions(false, token));
}

async function getPartners(token) {
    return await axiosInstancePublicApi.get("/user/partner", getBasicOptions(false, token));
}

async function favoritePartner(token, partnerId) {
    const data = {partnerId};
    return await axiosInstancePublicApi.post("/user/partner/favorite", JSON.stringify(data), getBasicOptions(true, token));
}

async function getBanks() {
    return await axiosInstancePublicApi.get("/bank", getBasicOptions(false));
}

async function getConsents(token) {
    return await axiosInstancePublicApi.get("/user/account-access-consents", getBasicOptions(false, token));
}

async function generateAuthorizationLink(token, bankId, accountNumber, bankLoginId) {
    const data = {accountNumber, bankLoginId};
    return await axiosInstancePublicApi.post("/consent/authorize/"+bankId, JSON.stringify(data), getBasicOptions(false, token));
}

async function checkConsentStatus(token, consentId) {
    return await axiosInstancePublicApi.get("/consent/status/"+consentId, getBasicOptions(false, token));
}

async function changeCouponStatus(token, couponId) {
    const data = {couponId};
    return await axiosInstancePublicApi.post("/user/coupon/status", JSON.stringify(data), getBasicOptions(false, token));
}

async function deleteAccount(token) {
    return await axiosInstancePublicApi.post("/user/account-delete", {}, getBasicOptions(false, token));
}

async function saveUserLocation(token, latitude, longitude) {
    return await axiosInstancePublicApi.post("/user/location", {latitude, longitude}, getBasicOptions(false, token));
}

async function getApiStatus() {
    return await axiosInstancePublicApi.get("/status/status", getBasicOptions(false, null));
}

async function subscribeToBank(token, bankId, email) {
    return await axiosInstancePublicApi.post("/bank/subscribe", {bankId, email}, getBasicOptions(false, token));
}

async function requestDisbursement(token, firstName, lastName, accountNumber, swiftCode) {
    return await axiosInstancePublicApi.post("/user/disbursement", {firstName, lastName, accountNumber, swiftCode}, getBasicOptions(false, token));
}

async function deleteConsent(token, consentId) {
    return await axiosInstancePublicApi.post("/consent/delete/"+consentId, {}, getBasicOptions(false, token));
}

async function userContact(token, email, name, message) {
    return await axiosInstancePublicApi.post("/user/contact", {email, name, message}, getBasicOptions(false, token));
}

export default {
    login, 
    loginFacebook, 
    register, 
    forgetPassword, 
    getRegions, 
    getProfile, 
    getUserFriends, 
    getUserSettings, 
    saveUserSettings, 
    changePassword, 
    getTransactions,
    getPartners, 
    favoritePartner, 
    getBanks, 
    getConsents, 
    generateAuthorizationLink, 
    checkConsentStatus,
    changeCouponStatus,
    deleteAccount,
    getApiStatus,
    userContact,
    subscribeToBank,
    saveUserLocation,
    deleteConsent,
    requestDisbursement
};