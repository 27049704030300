import { createSlice } from "@reduxjs/toolkit";
import { loginRequest, loginWithFacebookRequest, registerRequest } from "../ui/auth/store/LoginStore";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { favoritePartnerRequest } from "../ui/partners/store/PartnerStore";
import { changePasswordRequest, saveUserSettingsRequest } from "../ui/user/store/UserStore";

function getNotificationType(action) {
    return (action.payload && action.payload?.success) ? 'success' : 'error';
}

const commonSlice = createSlice({
    name: "common",
    initialState: {
        user: null,
        selectedTab: 0,
        showLoginForm: false,
        showRegisterForm: false,
        showForgetPasswordForm: false,
        notification: null
    },
    reducers: {
        showLogin: (state, action) => {
            state.showLoginForm = action.payload;
        },
        showRegister: (state, action) => {
            state.showLoginForm = !action.payload;
            state.showRegisterForm = action.payload;
        },
        showForgetPassword: (state, action) => {
            state.showForgetPasswordForm = action.payload;
        },
        showTab: (state, action) => {
            state.selectedTab = action.payload;
        },
        notify: (state, action) => {
            state.notification = action.payload;
        },
        notified: (state, action) => {
            state.notification = null;
        },
        logout : (state, action) => {
            state.user = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginRequest.fulfilled, (state, action) => {
            if(action.payload.success) {
                state.user = action.payload.data;
                state.showLoginForm = false;
            }
        });
        builder.addCase(loginWithFacebookRequest.fulfilled, (state, action) => {
            if(action.payload.success) {
                state.user = action.payload.data;
                state.showLoginForm = false;
            }
        });
        builder.addCase(registerRequest.fulfilled, (state, action) => {
            if(action.payload.success) {
                state.user = action.payload.data;
                state.showLoginForm = false;
            }
        });
        builder.addCase(favoritePartnerRequest.fulfilled, (state, action) => {
            state.notification = {
                type: getNotificationType(action),
                message: 'saved'
            };
        });
        builder.addCase(changePasswordRequest.fulfilled, (state, action) => {
            state.notification = {
                type: getNotificationType(action),
                message: 'password_changed'
            };
        });
        builder.addCase(changePasswordRequest.rejected, (state, action) => {
            state.notification = {
                type: 'error',
                message: 'error'
            };
        });
        builder.addCase(saveUserSettingsRequest.fulfilled, (state, action) => {
            state.notification = {
                type: getNotificationType(action),
                message: 'settings_saved'
            };
        });
        builder.addCase(saveUserSettingsRequest.rejected, (state, action) => {
            state.notification = {
                type: 'error',
                message: 'error'
            };
        });
    }
});

const commonReducerConfig = {
    key: 'commonReducer',
    storage: storage,
    blacklist: ['showLoginForm', 'showRegisterForm', 'showForgetPasswordForm', 'selectedTab', 'notification']
};

const commonReducer = persistReducer(commonReducerConfig, commonSlice.reducer);
const commonActions = commonSlice.actions;

export {
    commonReducer, commonActions
}