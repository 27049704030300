import React from 'react';
import { Box, TextField, Button } from '@mui/material';
import NavBackItem from './NavBackItem';

const ChangePasswordSettings = () => {
    return (
        <div className='change-pw setting-box-size'>
            <div className='setting-navigator'>
                <NavBackItem />
                <h3 className='title-md h-space'>Change Password</h3>
            </div>
            <div className='change-pw__details settings__details-wrapper '>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>
                <div className='change-pw-input-btn'>
                    <TextField
                        label='Old Password'
                        id='password'
                        name='password'
                        type='password'
                        color='primary'
                        className='login__input'
                        sx={{ mb: 1 }}
                    />
                    <TextField
                        label='New Password'
                        id='password'
                        name='password'
                        type='password'
                        color='primary'
                        className='login__input'
                        sx={{ mb: 1 }}
                    />
                    <TextField
                        label='New Password'
                        id='password'
                        name='password'
                        type='password'
                        color='primary'
                        className='login__input'
                        sx={{ mb: 1 }}
                    />
                    <Button className='btn btn--primary'>Save my Password</Button>
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordSettings;

/* onChange={formik.handleChange}
                            value={formik.values.password}
                            error={!!formik.errors.password}
                            helperText={formik.errors.password}
                            
                            onClick={onForgetPassword}
onClick={onForgetPassword}
                            */
