import CashbackCoupon from "../../../model/CashbackCoupon";
import PartnerCategory from "../../../model/PartnerCategory";
import PartnerModel from "../../../model/PartnerModel";
import { getPartnersRequest } from "../store/PartnerStore";
import BaseViewModel from "../../../model/viewModel/BaseViewModel";

class PartnerListViewModel extends BaseViewModel {

    constructor(data, user) {
        super();
        this.user = user;
        this.data = data;
        this.partners = [];
        this.categories = [];
        this.coupons = [];
        if(data && data.data) {
            this.initCategories(data.data.categories);
            this.initPartners(data.data.partners);
            this.initCoupons(data.data.cashbackCoupons);
        }
    }

    getData(dispatcher, forceReload) {
        if(!this.data || forceReload) {
            dispatcher(getPartnersRequest(this.user));
        }
    }

    initPartners(partnerData) {
        partnerData.forEach(i => {
            this.partners.push(new PartnerModel(i));
        });
    }

    initCoupons(couponData) {
        couponData.forEach(i => {
            this.coupons.push(new CashbackCoupon(i));
        });
    }

    initCategories(categoryData) {
        categoryData.forEach(i => {
            this.categories.push(new PartnerCategory(i));
        });
    }

    getDefaultList(searchQuery) {
        let byCategory = {};
        this.partners.forEach(i => {
            let categoryId = i.categoryId;
            if(!byCategory[categoryId]) {
                byCategory[categoryId] = {title: this.findCategoryName(categoryId), items: []};
            }
            if(searchQuery && searchQuery.length > 1) {
                if(i.search(searchQuery)) {
                    byCategory[categoryId].items.push(i);
                }
            } else {
                byCategory[categoryId].items.push(i);
            }
        });
        return byCategory;
    }

    findCategoryName(categoryId) {
        let it = this.categories.filter(i => {
            return (i.id == categoryId);
        });
        if(it.length > 0) {
            return it[0].name;
        }
        return "";
    }

    getPartnerIdFromPath(path) {
        return path.split("/").reverse()[0];
    }

    getPartnerById(id) {
        let found = this.partners.find(i => {
            return i.id == id;
        });
        return found;
    }

    findCouponForPartner(partnerId) {
        if(!partnerId) {
            return null;
        }
        return this.coupons.filter(i => i.partnerId == partnerId);
    }

}

export default PartnerListViewModel;