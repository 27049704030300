import BaseViewModel from "../../../model/viewModel/BaseViewModel";
import { deleteAccountRequest, getUserProfileRequest } from "../store/UserStore";

class UserProfileViewModel extends BaseViewModel {

    constructor(data, user) {
        super();
        this.data = data?.data;
        this.user = user;
    }

    getData(dispatcher) {
        if(!this.data) {
            dispatcher(getUserProfileRequest(this.user));
        }
    }

    deleteAccount(dispatcher) {
        if(!this.user) {
            return;
        }
        return dispatcher(deleteAccountRequest(this.user)).unwrap();
    }

    getInvitePayment() {
        const referralData = this.data?.referral;
        return this.formatCurrency(referralData?.referralPayment, referralData?.currencyCode);
    }

}

export default UserProfileViewModel;