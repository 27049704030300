import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import CardMedia from '@mui/material/Card';
import Typography from '@mui/material/Typography';

export default ({ model, item, clickHandler }) => {
    let fullUrl = item.getPartnerLogo(model);
    return (
        <>
            <div className='coupon__card'>
                <img src={fullUrl} alt='logo' />
                <Typography variant='body1'>{item.getValueText(model)}</Typography>
            </div>

            {/*     <Card sx={{display: 'flex', flexDirection: 'column',minHeight: '200px', justifyContent: "space-between"}}>
            <CardActionArea onClick={() => clickHandler?.(item.id)} sx={{flexGrow: 1, flexDirection: 'column', alignItems: 'stretch',}}>
                <CardMedia component="img" width="30%" src={fullUrl} alt="logo" />
                <CardContent sx={{flexGrow: 1}}>
                    <Typography variant="body1" component="div">{item.getValueText(model)}</Typography>
                </CardContent>
            </CardActionArea>
        </Card> */}
        </>
    );
};
