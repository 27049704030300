import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonViewModel from '../common/model/CommonViewModel';
import UserProfileViewModel from './model/UserProfileViewModel';
import UserModel from '../../model/UserModel';
import { useState } from 'react';
import UserSettingsComponent from './ui/UserSettingsComponent';
import UserChangePasswordComponent from './ui/UserChangePasswordComponent';
import UserNotificationSettingsComponent from './ui/UserNotificationSettingsComponent';
import UserSettingsViewModel from './model/UserSettingsViewModel';
import { userStore } from './store/UserStore';
import UserAccountNumberComponent from './ui/UserAccountNumberComponent';
import UserRegionSelectComponent from './ui/UserRegionSelectComponent';
import {
    Modal,
    Stack,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Dialog,
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    TextField,
    FormGroup,
    FormControlLabel,
    Switch,
    IconButton,
    Typography,
    InputAdornment,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const ProfileComponent = () => {
    const [settings, showSettings] = useState(false);
    const [validationErrors, setValidationErrors] = useState(null);
    const [changePasswordState, showChangePassword] = useState(false);
    const [notificationsState, showSetNotifications] = useState(false);
    const [accountNumberState, showSetAccountNumber] = useState(false);
    const [regionState, showSetRegion] = useState(false);
    const [deleteAccount, showDeleteAccount] = useState(false);
    const dispatcher = useDispatch();
    //Data
    const commonModel = new CommonViewModel();
    const userData = useSelector((state) => commonModel.getUser(state));
    const userProfile = useSelector((state) => commonModel.getProfileData(state));
    const userSettings = useSelector((state) => commonModel.getUserSettingsData(state));
    const user = new UserModel(userData);
    const profileModel = new UserProfileViewModel(userProfile, user);
    const model = new UserSettingsViewModel(userSettings, user);
    useEffect(() => model.getData(dispatcher));
    /* if (!model.data) {
        return '';
    }
    const changePassword = () => {
        showChangePassword(true);
        showSettings(false);
    };
    const saveNewPassword = (old, p1, p2) => {
        const errors = model.validateNewPassword(old, p1, p2);
        if (errors) {
            setValidationErrors(errors);
        } else {
            setValidationErrors(null);
            model.saveNewPassword(dispatcher, old, p1).then(
                (res) => {
                    closeSubDialogs();
                },
                (e) => {}
            );
        }
    };
    const setNotifications = () => {
        showSetNotifications(true);
        showSettings(false);
    };
    const saveNotificationSettings = (email) => {
        let newSettings = Object.assign({}, model.data);
        newSettings.email = email;
        model.saveSettings(dispatcher, newSettings).then(
            (res) => {
                closeSubDialogs();
                dispatcher(userStore.actions.updateSettings());
            },
            (e) => {}
        );
    };

    const savePushState = (state) => {
        let newSettings = Object.assign({}, model.data);
        newSettings.pushEnabled = state;
        model.saveSettings(dispatcher, newSettings).then(
            (res) => {
                dispatcher(userStore.actions.updateSettings());
            },
            (e) => {}
        );
    };

    const saveNewsLetterState = (state) => {
        let newSettings = Object.assign({}, model.data);
        newSettings.tipsEnabled = state;
        model.saveSettings(dispatcher, newSettings).then(
            (res) => {
                dispatcher(userStore.actions.updateSettings());
            },
            (e) => {}
        );
    };

    const setAccountNumber = () => {
        showSetAccountNumber(true);
        showSettings(false);
    };

    const saveAccountNumber = (number1, number2, number3) => {
        const errors = model.validateAccountNumber(number1, number2, number3);
        if (errors) {
            setValidationErrors(errors);
        } else {
            setValidationErrors(null);
            let newSettings = Object.assign({}, model.data);
            let newAccountNumber = {
                accountNumber: number1 + number2 + number3,
                swiftCode: newSettings.accountNumber.swiftCode,
            };
            newSettings.accountNumber = newAccountNumber;
            model.saveSettings(dispatcher, newSettings).then(
                (res) => {
                    closeSubDialogs();
                    dispatcher(userStore.actions.updateSettings());
                },
                (e) => {}
            );
        }
    };

    const setRegion = () => {
        showSetRegion(true);
        showSettings(false);
    };

    const saveRegion = (data) => {
        let newSettings = Object.assign({}, model.data);
        newSettings.userRegion = data;
        model.saveSettings(dispatcher, newSettings).then(
            (res) => {
                closeSubDialogs();
                dispatcher(userStore.actions.updateSettings());
            },
            (e) => {}
        );
    };

    const closeSubDialogs = () => {
        showChangePassword(false);
        showSetNotifications(false);
        showSetAccountNumber(false);
        showSetRegion(false);
        showSettings(true);
    };

  */
    const handleClose = () => {
        showDeleteAccount(false);
    };

    const onDeleteAccount = () => {
        profileModel.deleteAccount(dispatcher).then(
            (res) => {
                dispatcher(userStore.actions.logout());
            },
            (e) => {}
        );
    };
    const svgIcon = () => {
        return (
            <svg
                className='w-6 h-6 text-gray-800 dark:text-white'
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 20 16'
            >
                <path
                    stroke='currentColor'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M8 8v1h4V8m4 7H4a1 1 0 0 1-1-1V5h14v9a1 1 0 0 1-1 1ZM2 1h16a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1Z'
                />
            </svg>
        );
    };
    const [newsLetterChecked, setNewsLetterChecked] = useState(false);
    const [pushNotChecked, setPushNotChecked] = useState(false);
    const [email, setEmail] = useState('example@example.com');
    const [bankAccount, setBankAccount] = useState('12345678-12345678-12345678');
    const [isBankEditing, setIsBankEditing] = useState(false);
    const [isEmailEditing, setIsEmailEditing] = useState(false);

    const [selectedCountry, setSelectedCountry] = useState('');

    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };

    return (
        <Box>
            <h2 className='title-md h-space'>Profile</h2>
            <h3 className='title-sm h-space-sm'>Account settings</h3>
            <div className='profile__box'>
                <div className='profile__left'>{svgIcon()}</div>
                <div className='profile__right'>
                    <h4 className='profile__title | title-xs '>Bank Account</h4>
                    <TextField
                        className='profile__input'
                        value={bankAccount}
                        disabled={!isBankEditing}
                        onChange={(e) => setBankAccount(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    {isBankEditing ? (
                                        <IconButton onClick={() => setIsBankEditing(false)}>
                                            <SaveIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton onClick={() => setIsBankEditing(true)}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </div>
            <div className='profile__box profile__box-btn-only '>
                <div className='profile__left'>{svgIcon()}</div>
                <div className='profile__right'>
                    <HashLink to='/profile/change-password#top' className='btn profile__title | title-xs '>
                        Change Password
                    </HashLink>
                </div>
            </div>
            <div className='profile__box '>
                <div className='profile__left'>{svgIcon()}</div>
                <div className='profile__right'>
                    <h4 className='profile__title | title-xs '>Region Selector</h4>
                    <FormControl className='profile__input'>
                        <InputLabel id='demo-simple-select-label'>Region</InputLabel>
                        <Select labelId='demo-simple-select-label' id='demo-simple-select' label='Region'>
                            {/*  {regionList &&
                                regionList.map((i) => {
                                    return (
                                        <MenuItem key={'region_' + i.id} value={i.id}>
                                            {t('region_' + i.id)}
                                        </MenuItem>
                                    );
                                })} */}
                        </Select>
                        {/*   value={formik.values.region}
                            onChange={(event) => {
                                formik.setFieldValue('region', event.target.value);
                            }} */}
                    </FormControl>
                </div>
            </div>
            <div className='profile__box '>
                <div className='profile__left'>{svgIcon()}</div>
                <div className='profile__right'>
                    <h4 className='profile__title | title-xs '>Language Selector</h4>
                    <FormControl className='profile__input'>
                        <InputLabel id='country-label'>Country</InputLabel>
                        <Select labelId='country-label' id='country-select' value={selectedCountry} onChange={handleCountryChange}>
                            <MenuItem value='United Kingdom'>United Kingdom</MenuItem>
                            <MenuItem value='Hungary'>Hungary</MenuItem>
                        </Select>
                    </FormControl>
                    {/* <FormControl className='profile__input'>
                        <InputLabel id='demo-simple-select-label'>Language</InputLabel>
                        <Select labelId='demo-simple-select-label' id='demo-simple-select' label='Region'></Select>
                    </FormControl> */}
                </div>
            </div>
            <h3 className='title-sm h-space-sm'>Communcations</h3>
            <div className='profile__box '>
                <div className='profile__left'>{svgIcon()}</div>
                <div className='profile__right'>
                    <h4 className='profile__title | title-xs '>Email address for notifications</h4>
                    <p>Erre az email címre küldjük a fontosabb rendszerüzeneteket. Harmadik fél részére nem adjuk tovább.</p>
                    <TextField
                        className='profile__input'
                        sx={{ mt: 2 }}
                        value={email}
                        disabled={!isEmailEditing}
                        onChange={(e) => setEmail(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    {isEmailEditing ? (
                                        <IconButton onClick={() => setIsEmailEditing(false)}>
                                            <SaveIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton onClick={() => setIsEmailEditing(true)}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </div>
            <div className='profile__box  profile__box-btn-only'>
                <div className='profile__left'>{svgIcon()}</div>
                <div className='profile__right'>
                    <div className='toggle-menu'>
                        <div>
                            <h4 className='profile__title | title-xs '>Newsletter</h4>
                            <p className='text-light'>Stay up to date with ReCash news! </p> {/* LIGHT TEXT */}
                        </div>
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={newsLetterChecked} onChange={() => setNewsLetterChecked(!newsLetterChecked)} />}
                                label={newsLetterChecked ? 'Yes' : 'No'}
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className='profile__box  profile__box-btn-only'>
                <div className='profile__left'>{svgIcon()}</div>
                <div className='profile__right'>
                    <div className='toggle-menu'>
                        <div>
                            <h4 className='profile__title | title-xs '>Push notifications</h4>
                            <p className='text-light'>We will send important info to your phone! </p> {/* LIGHT TEXT */}
                        </div>
                        {/*       <FormGroup>
                            <FormControlLabel control={<Switch checked={pushEnabled} onChange={onPushEnabledChanged} />} label='Push notifications' />
                        </FormGroup> */}
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch checked={pushNotChecked} onChange={() => setPushNotChecked(!pushNotChecked)} />}
                                label={pushNotChecked ? 'Yes' : 'No'}
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <h3 className='title-sm h-space-sm'>Support</h3>
            <div className='profile__box profile__box-btn-only'>
                <div className='profile__left'>{svgIcon()}</div>
                <div className='profile__right'>
                    <HashLink to='/profile/terms-and-conditions#top' className='btn profile__title | title-xs '>
                        Terms and conditions
                    </HashLink>
                </div>
            </div>
            <div className='profile__box profile__box-btn-only'>
                <div className='profile__left'>{svgIcon()}</div>
                <div className='profile__right  '>
                    <HashLink smooth to='/profile/contact-us#top' className='btn profile__title  | title-xs '>
                        Contact us
                    </HashLink>
                </div>
            </div>
            <div className='profile__box profile__box-btn-only'>
                <div className='profile__left'>{svgIcon()}</div>
                <div className='profile__right '>
                    <HashLink to='/profile/FAQ#top' className='btn profile__title  | title-xs '>
                        FAQ
                    </HashLink>
                </div>
            </div>
            <div className='profile__box profile__box-btn-only'>
                <div className='profile__left'>{svgIcon()}</div>
                <div className='profile__right '>
                    <HashLink to='/profile/about-us#top' className='btn profile__title  | title-xs '>
                        About us
                    </HashLink>
                </div>
            </div>
            <Button className='btn profile__title' onClick={() => showDeleteAccount(true)}>
                Delete Profil
            </Button>{' '}
            {/*       <Stack direction='row' spacing={2}>
                <Button variant='contained' onClick={() => showSettings(true)}>
                    Settings
                </Button>
                <Button variant='contained' color='error' onClick={() => showDeleteAccount(true)}>
                    Delete profile
                </Button>
            </Stack>
            <Modal open={settings}>
                <Box style={{ width: '30%', background: 'white', position: 'absolute', top: '20%', left: '50%', transform: 'translate(-50%, 0%)' }}>
                    <UserSettingsComponent
                        model={model}
                        closeDialog={() => showSettings(false)}
                        changePassword={changePassword}
                        setNotifications={setNotifications}
                        savePushState={savePushState}
                        saveNewsLetterState={saveNewsLetterState}
                        setAccountNumber={setAccountNumber}
                        setRegion={setRegion}
                    />
                </Box>
            </Modal>
            <Modal open={changePasswordState}>
                <Box style={{ width: '30%', background: 'white', position: 'absolute', top: '20%', left: '50%', transform: 'translate(-50%, 0%)' }}>
                    <UserChangePasswordComponent validationErrors={validationErrors} saveDialog={saveNewPassword} closeDialog={closeSubDialogs} />
                </Box>
            </Modal>
            <Modal open={notificationsState}>
                <Box style={{ width: '30%', background: 'white', position: 'absolute', top: '20%', left: '50%', transform: 'translate(-50%, 0%)' }}>
                    <UserNotificationSettingsComponent
                        model={model}
                        validationErrors={validationErrors}
                        saveDialog={saveNotificationSettings}
                        closeDialog={closeSubDialogs}
                    />
                </Box>
            </Modal>
            <Modal open={accountNumberState}>
                <Box style={{ width: '30%', background: 'white', position: 'absolute', top: '20%', left: '50%', transform: 'translate(-50%, 0%)' }}>
                    <UserAccountNumberComponent
                        model={model}
                        validationErrors={validationErrors}
                        saveDialog={saveAccountNumber}
                        closeDialog={closeSubDialogs}
                    />
                </Box>
            </Modal>
            <Modal open={regionState}>
                <Box style={{ width: '30%', background: 'white', position: 'absolute', top: '20%', left: '50%', transform: 'translate(-50%, 0%)' }}>
                    <UserRegionSelectComponent
                        model={model}
                        validationErrors={validationErrors}
                        saveDialog={saveRegion}
                        closeDialog={closeSubDialogs}
                    />
                </Box>
            </Modal> */}
            <Dialog
                open={deleteAccount}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                className='profile__delete-dialog'
                maxWidth={'md'}
                fullWidth
                fullScreen={window.innerWidth > 600 ? false : true}
            >
                <svg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        d='M3 9.22843V14.7716C3 15.302 3.21071 15.8107 3.58579 16.1858L7.81421 20.4142C8.18929 20.7893 8.69799 21 9.22843 21H14.7716C15.302 21 15.8107 20.7893 16.1858 20.4142L20.4142 16.1858C20.7893 15.8107 21 15.302 21 14.7716V9.22843C21 8.69799 20.7893 8.18929 20.4142 7.81421L16.1858 3.58579C15.8107 3.21071 15.302 3 14.7716 3H9.22843C8.69799 3 8.18929 3.21071 7.81421 3.58579L3.58579 7.81421C3.21071 8.18929 3 8.69799 3 9.22843Z'
                        stroke='#323232'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                    />
                    <path d='M12 8V13' stroke='#323232' stroke-width='2' stroke-linecap='round' />
                    <path d='M12 16V15.9888' stroke='#323232' stroke-width='2' stroke-linecap='round' />
                </svg>
                <p className='title-md'>You are about to delete your profile. This action cannot be undone.</p>
                <div className='btns'>
                    <Button className='btn btn--primary' onClick={handleClose}>
                        No, do not delete my profile
                    </Button>
                    <Button className='btn btn--mui-outline ' onClick={onDeleteAccount}>
                        Yes, I want to delete my profile
                    </Button>
                </div>
            </Dialog>
        </Box>
    );
};

export default ProfileComponent;
