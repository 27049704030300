import { getRegionsRequest } from "../../user/store/UserStore";

class CommonViewModel {

    getRegions(state) {
        return state.userStoreReducer.regions;
    }

    loadRegions(dispatcher, load, token) {
        if(load) {
            dispatcher(getRegionsRequest(token));
        }
    }

    getUser(state) {
        return state.commonReducer.user;
    }

    getProfileData(state) {
        return state.userStoreReducer.userProfile;
    }

    getUserFriendsData(state) {
        return state.userStoreReducer.userFriends;
    }

    getUserSettingsData(state) {
        return state.userStoreReducer.userSettings;
    }

    getForceReload(state) {
        return state.loginStoreReducer.userLoggedInReload;
    }

    getPartnerData(state) {
        return state.partnerStoreReducer.partnerData;
    }

    getBankData(state) {
        return state.bankStoreReducer.bankData;
    }

    getBankSearchQuery(state) {
        return state.bankStoreReducer.bankSearchQuery;
    }

    getConsents(state) {
        return state.consentStoreReducer.consentList;
    }

    getCouponData(state) {
        return state.partnerStoreReducer.selectedCoupon;
    }

    getTransactionsData(state) {
        return state.transactionStoreReducer.transactions;
    }

}

export default CommonViewModel;