import { useTranslation } from "react-i18next";
import useGeolocation from "react-hook-geolocation";
import PartnersBasicCarousel from "../../partners/ui/PartnersBasicCarousel";
import BasicAvatarTitle from "../../common/BasicAvatarTitle";
import { useDispatch, useSelector } from "react-redux";
import { loginStore } from "../../auth/store/LoginStore";
import { useState } from "react";

export default({model}) => {
    const dispatcher = useDispatch();
    const { t, i18n } = useTranslation('home');
    const geoloc = useGeolocation();
    const {partnerNum, partnerData} = model.getLocationSortedPartnerList(geoloc, 20);
    const hasGeo = !(geoloc?.error);
    const lastLocation = useSelector(state => state.loginStoreReducer.lastLocationSaved);
    const [locationSaved, setLocationSaved] = useState(false);
    if(hasGeo && !locationSaved) {
        if(lastLocation) {
            const lastLocationDate = new Date(lastLocation.date).getTime();
            const now = new Date().getTime();
            const dateDiffInHours = (now - lastLocationDate) / 1000 / 60 / 60;
            if(dateDiffInHours < 24) {
                setLocationSaved(true);
                return;
            }
        }
        model.saveUserLocation(dispatcher, geoloc?.latitude, geoloc?.longitude).unwrap().then(result => {
            //Save last regardless of status
            const lastLocation = {date: new Date().toJSON(), latitude: geoloc?.latitude, longitude: geoloc?.longitude};
            dispatcher(loginStore.actions.saveLocation(lastLocation));
            setLocationSaved(true);
        });
    }
    return(
        <div>
            <BasicAvatarTitle title={t('near_me')} titleNum={partnerNum} />
            {hasGeo && partnerData && 
            <PartnersBasicCarousel model={model} key="nearmeList" partners={partnerData} clickHandler={(data) => model.onPartnerItemClicked('HomeNearMeComponent', data)} buttonClickHandler={(data) => model.onPartnerRowButtonClicked('HomeNearMeComponent', data)} />
            }
            {!hasGeo &&
            <p>{t('you_have_to_enable_location')}</p>
            }
        </div>
    );
}