import { useTranslation } from 'react-i18next';
import PartnersBasicCarousel from '../../partners/ui/PartnersBasicCarousel';

export default ({ model, clickHandler, buttonClickHandler }) => {
    const { t, i18n } = useTranslation('home');
    let { partnerNum, partnerData } = model.getDefaultSortedPartnerList(20);

    const category = [
        'beauty',
        'car application',
        'education',
        'health',
        'food and drink',
        'house and garden',
        'sports',
        'kids',
        'everyday',
        'fashion',
        'technology',
    ];
    return (
        <>
            <div>
                <h4>Categories</h4>
                <div className='category__wrapper'>
                    {category.map((category, index) => (
                        <div key={index} className={`category `}>
                            {/* TODO hozzáadni az active categoryt */}
                            <svg
                                className='w-6 h-6 text-gray-800 dark:text-white'
                                aria-hidden='true'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 14 20'
                            >
                                <path
                                    stroke='currentColor'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='m13 19-6-5-6 5V2a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v17Z'
                                />
                            </svg>
                            <p className='title'>{category}</p>
                        </div>
                    ))}
                </div>
            </div>
            <PartnersBasicCarousel
                model={model}
                partners={partnerData}
                title={t('partners')}
                titleNum={partnerNum}
                clickHandler={(data) => model.onPartnerItemClicked('HomePartnersComponent', data)}
                buttonClickHandler={(data) => model.onPartnerRowButtonClicked('HomePartnersComponent', data)}
            />
        </>
    );
};
