import { Button, Modal, Stack, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { Box } from "@mui/material";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { commonActions } from "../../../reducer/CommonReducer";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '80%',
    overFlowX: 'scroll',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
};

export default({model, show, bank, onCloseHandler}) => {
    const { t, i18n } = useTranslation('translation');
    const dispatcher = useDispatch();
    const user = useSelector(state => state.loginStoreReducer.user);

    const formik = useFormik({
        initialValues: {
          email: ''
        },
        validationSchema: Yup.object({
          email: Yup.string().max(255).required(t('email_is_required')).email(t('must_be_valid_email'))
        }),
        onSubmit: values => {
            model.onSubscribeToBank(dispatcher, bank?.id, values.email).unwrap().then(result => {
                dispatcher(commonActions.notify({type: 'success', message: 'subscribed_to_bank'}));
                onCloseHandler();
            });
        },
    });

    return(
        <Modal open={show} aria-labelledby="modal-modal-title" aria-describedby="Bank details">
            <Box sx={style}>
                <Typography variant="h5" gutterBottom>{bank?.name}</Typography>
                <Typography>This bank is unavailable. If you wish to get notified when it becomes available please subscribe</Typography>
                <form onSubmit={formik.handleSubmit}>
                    <Stack direction="column" spacing={2} sx={{maxWidth: '300px'}}>
                        <TextField 
                            label="E-mail" 
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            error={!!formik.errors.email} 
                            helperText={formik.errors.email}
                            color="primary" />
                        <Button variant="contained" type="submit">Subscribe</Button>
                    </Stack>
                </form>
                <Button variant="contained" sx={{position: 'absolute', bottom: '12px', right: '12px'}} onClick={onCloseHandler}>Close</Button>
            </Box>
        </Modal>
    );
}