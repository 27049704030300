import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { commonActions } from '../../reducer/CommonReducer';

export default() => {
    const dispatcher = useDispatch();
    const onLoginClick = () => {
        dispatcher(commonActions.showLogin(true));
    }
    return(
        <Box sx={{ maxWidth: '300px', m: 'auto', marginTop: '30px' }}>
            <Stack spacing={2}>
                <h3>Login needed</h3>
                <p>In order to connect your bank, see transactions and get cashbacks and coupons, please log in first</p>
                <Button sx={{ maxWidth: '120px' }} variant="contained" onClick={() => onLoginClick()}>Login</Button>
            </Stack>
        </Box>
    );
}