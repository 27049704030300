import Input from '@mui/joy/Input';
import SearchRounded from '@mui/icons-material/SearchRounded';
import BanksCarousel from "./ui/BanksCarousel";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import BankDetailsDialog from './ui/BankDetailsDialog';
import BankViewModel from './model/BankViewModel';
import UserModel from '../../model/UserModel';
import CommonViewModel from '../common/model/CommonViewModel';
import { Box } from '@mui/material';
import BankSubscribeDialog from './ui/BankSubscribeDialog';

export default() => {
    const dispatcher = useDispatch();
    let commonModel = new CommonViewModel();
    const userData = useSelector(state => commonModel.getUser(state));
    const bankData = useSelector(state => commonModel.getBankData(state));
    const model = new BankViewModel(bankData, new UserModel(userData));
    const bankSearchQuery = useSelector(state => commonModel.getBankSearchQuery(state));
    const forceReload = useSelector(state => commonModel.getForceReload(state));
    const [subToBank, setSubToBank] = useState(null);
    useEffect(() => model.getBankData(dispatcher, forceReload));
    const bankList = model.getDefaultList(bankSearchQuery);
    const onBankClickedHandler = (id) => {
        const bank = bankData?.data?.banks.find(i => i.id == id);
        if(bank?.status== "ACTIVE") {
            model.onBankClicked(id, dispatcher);
        }
        else {
            setSubToBank(bank);
        }
    }
    const onCancelSubHandler = () => {
        setSubToBank(null);
    }
    return(
        <Box>
            <Input autoFocus placeholder="search" startDecorator={<SearchRounded />} onChange={(e) => model.onBankSearched(e, dispatcher)} defaultValue={bankSearchQuery} />
            <h2>Banks</h2>
            <BanksCarousel model={model} banks={bankList} clickHandler={onBankClickedHandler} />
            <BankDetailsDialog />
            <BankSubscribeDialog model={model} show={!!subToBank} bank={subToBank} onCloseHandler={onCancelSubHandler} />
        </Box>
    );
}