import { Box, Button } from '@mui/material';
import BasicAvatarTitle from '../../common/BasicAvatarTitle';
import PartnersMainCarousel from './PartnersMainCarousel';

export default ({ model, title, partners, clickHandler, buttonClickHandler, showMore }) => {
    const titleNum = partners.length;

    return (
        <>
            <Box sx={{ marginBottom: 4 }}>
                {title && <BasicAvatarTitle title={title} titleNum={titleNum} />}
                {titleNum > 0 && <PartnersMainCarousel model={model} partners={partners} clickHandler={clickHandler} />}
                {titleNum == 0 && <p>No items</p>}
                {showMore && (
                    <Box sx={{ marginTop: '20px', textAlign: 'right' }}>
                        <Button variant='contained' onClick={() => buttonClickHandler?.(title)}>
                            More
                        </Button>
                    </Box>
                )}
            </Box>
        </>
    );
};
