import React from 'react';
import { Box, TextField, Button } from '@mui/material';
import NavBackItem from './NavBackItem';

const FaqSettings = () => {
    return (
        <div className='setting-box-size'>
            <div className='setting-navigator'>
                <NavBackItem />
                <h3 className='title-md h-space'>FAQ</h3>
            </div>
            <div className='settings__details-wrapper'></div>
        </div>
    );
};

export default FaqSettings;
