import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonViewModel from '../common/model/CommonViewModel';
import UserProfileViewModel from '../user/model/UserProfileViewModel';
import UserProfileModel from '../../model/UserProfileModel';
import UserModel from '../../model/UserModel';
import { Tab, Snackbar, Stack, Tabs, Box, Button, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QRCode from 'react-qr-code';
import InviteViewModel from './model/InviteViewModel';
import CheckIcon from '@mui/icons-material/Check';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import LoginNeededComponent from '../common/LoginNeededComponent';
import TabPanel from '../common/TabPanel';
import { Typography } from '@mui/joy';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import GoogleIcon from '@mui/icons-material/Google';
import ForumIcon from '@mui/icons-material/Forum';

const InviteComponent = () => {
    const [showSnack, setShowSnack] = useState(false);
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const dispatcher = useDispatch();
    //Data
    const commonModel = new CommonViewModel();
    const userData = useSelector((state) => commonModel.getUser(state));
    const userProfile = useSelector((state) => commonModel.getProfileData(state));
    const user = new UserModel(userData);
    const model = new UserProfileViewModel(new UserProfileModel(userProfile), user);
    const userFriends = useSelector((state) => commonModel.getUserFriendsData(state));
    const inviteModel = new InviteViewModel(userFriends, user);
    useEffect(() => inviteModel.getData(dispatcher));
    if (!userData) {
        return <LoginNeededComponent />;
    }
    if (!inviteModel.data) {
        return '';
    }
    const onCopyClicked = (e) => {
        navigator.clipboard.writeText(model.data?.referral?.link);
        setShowSnack(true);
    };
    const referralAmount = model.getInvitePayment();
    let friendList = inviteModel.getDefaultList();

    const handleCopy = () => {};

    const invitedFriendTest = [
        { email: 'alma312312132123@gmail.com', date: '2022.05.22', stat: 'Bank limited', reward: '6000' },
        { email: 'alma@gmail.com', date: '2022.05.22', stat: 'Bank limited', reward: '6000' },
        { email: 'alma@gmail.com', date: '2022.05.22', stat: 'Bank limited', reward: '6000' },
        { email: 'alma@gmail.com', date: '2022.05.22', stat: 'Bank limited', reward: '6000' },
        { email: 'alma@gmail.com', date: '2022.05.22', stat: 'Bank limited', reward: '6000' },
    ];
    return (
        <Box>
            <h4 className='h-space title-md'> Referal Program</h4>
            <Box className='referal-box'>
                <Typography sx={{ mb: 2 }}>Invite your firends and earn cashback!</Typography>
                <Typography sx={{ mb: 2 }}>
                    Earn 1000 HUF for each friend you invite. After your friend registers and receives their forst cashback, both you and your friend
                    will get 1000 HUF.
                </Typography>
                <Box className='flex' sx={{ flexWrap: { xs: 'wrap', sm: 'nowrap' }, gap: '10px' }}>
                    <p className='disabled-text' sx={{ width: '100%', overflow: 'hidden', borderColor: 'gray' }}>
                        https:///thisisalinktoshare
                    </p>
                    {/* TODO: paste the same translate key to p and onClick */}
                    <Button
                        className='btn btn__login'
                        sx={{ width: '100%' }}
                        onClick={() => {
                            setShowSnack(true);
                            navigator.clipboard.writeText(' https:///thisisalinktoshare');
                        }}
                    >
                        Copy link
                    </Button>
                </Box>
                {/* TODO: add links to A-tags (icons)*/}
                <div className='icons flex'>
                    <a href=''>
                        <ForumIcon />
                    </a>
                    <a href=''>
                        <WhatsAppIcon />
                    </a>
                    <a href=''>
                        <GoogleIcon />
                    </a>
                    <a href=''>
                        <TwitterIcon />
                    </a>
                    <a href=''>
                        <FacebookIcon />
                    </a>
                </div>
                <div className='qr-code'>
                    <QRCode value='model.data?.referral?.qrLink' />
                </div>
                {/*  <Box className='flex'>
                    <Button className='btn btn--download'>Download our QR code as a pdf</Button>
                </Box> */}
            </Box>
            <Box className='invite-box'>
                <h5 className='h-space title-md'>My Invites</h5>
                <div className='invite__table'>
                    <p className='title'>Email address</p>
                    <p className='title on-big-sreen'>Date of registration</p>
                    <p className='title on-big-sreen'>Status</p>
                    <div className='title reward__info' information='You can find your rewards in your “my balance” menü.'>
                        Reward
                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50' width='50px' height='50px'>
                            <path d='M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z' />
                        </svg>
                    </div>
                    {friendList.length > 0 &&
                        friendList.map((friend) => (
                            <Fragment key={friend?.email}>
                                <p className='details'>{friend?.email}</p>
                                {/*  <p className='details on-big-sreen'>{friend?.date}</p>
                                <p className='details on-big-sreen'>{friend?.stat}</p>
                                <p>
                                    {friend.reward} {'HUF'}
                                </p> */}
                            </Fragment>
                        ))}
                </div>
                {friendList?.length === 0 && <Typography sx={{ mt: 2, ml: 1 }}>You haven't invited any of your friends yet.</Typography>}
            </Box>
            <Snackbar open={showSnack} autoHideDuration={3000} message='Copied to clipboard' onClose={() => setShowSnack(false)} />
        </Box>
    );
};

export default InviteComponent;
