import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        lng: 'en',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                auth: {
                    register: 'Register',
                    email: 'E-mail',
                    password: 'Password',
                    password_again: 'Password again',
                    region_hu: 'Hungary',
                    region_uk: 'United Kingdom',
                    email_is_required: 'Email is required',
                    must_be_valid_email: 'Must be a valid email address',
                    password_is_required: 'Password is required',
                    password_again_is_required: 'Password again is required',
                    must_be_min_8_characters: 'Password must be at least 8 characters',
                    password_complexity_error: 'Please choose a stronger password',
                    password_match_error: 'The two passwords must match',
                },
                menu: {
                    home: 'Home',
                    partners: 'Partners',
                    banks: 'Banks',
                    consents: 'Consents',
                    transactions: 'Transactions',
                    invite: 'Invite',
                    help: 'Help',
                    about: 'About',
                    login: 'Log in',
                    profile: 'Profile',
                    notifications: 'Notifications',
                    contact_us: 'Contact us',
                    logout: 'Logout',
                },
                home: {
                    partners: 'Partners',
                    favorites: 'Favorites',
                    no_favorites: 'You have no favorites',
                    please_log_in_favorites: 'Please log in to use favorites',
                    cashback_coupons: 'Cashback coupons',
                    no_cashback_coupons: 'You have no cashback coupons',
                    please_log_in_coupons: 'Please log in to use coupons',
                    near_me: 'Near me',
                    you_have_to_enable_location: 'You have to enable location to see the list',
                    system_message: 'System message',
                },
                consents: {
                    account_number_required: 'Account number is required',
                    account_number_not_numeric: 'Account number must be numeric',
                    account_number_length: 'Account number must be 16 or 24 long',
                    bank_login_id_short: 'Your direct ID must be longer',
                    bank_login_id_required: 'Your bank requires your direct ID',
                    consent_status_approved: 'Approved',
                    consent_status_pending: 'Pending',
                    consent_status_required: 'Required',
                    consent_status_leaving: 'Leaving',
                    consent_delete_approve: 'Are you sure you want to delete this consent? This cannot be undone',
                },
                discounts: {
                    no_rescrictions: 'No restrictions',
                    no_rescrictions_shop: 'No restrictions in shop',
                    no_rescrictions_webshop: 'No restrictions in webshop',
                    no_time_rescrictions: 'No time restrictions',
                    shop_buys: 'In shops',
                    webshop_buys: 'In webshops',
                    only_shop: 'Only in shops',
                    only_webshop: 'Only in webshop',
                    only_times: 'Only in selected times',
                    other_times: 'Other times',
                    day_monday: 'Monday',
                    day_tuesday: 'Tuesday',
                    day_wednesday: 'Wednesday',
                    day_thursday: 'Thursday',
                    day_friday: 'Friday',
                    day_saturday: 'Saturday',
                    day_sunday: 'Sunday',
                },
                profile: {
                    region_hu: 'Hungary',
                    region_uk: 'United Kingdom',
                    current_password: 'Current password',
                    new_password: 'New password',
                    new_password_again: 'New pasword again',
                    first_8_account_number: 'First 8 number',
                    second_8_account_number: 'Second 8 number',
                    third_8_account_number: 'Third 8 number (optional)',
                    cant_be_empty: 'This field is required',
                    password_too_short: 'Password must be at least 8 characters long',
                    password_too_long: 'The password is too long',
                    passwords_dont_match: 'The two passwords do not match',
                    must_be_numeric: 'This field must be a number',
                    must_be_8_long: 'The number must be 8 characters long',
                },
                transactions: {
                    first_name_is_required: 'First name is required',
                    last_name_is_required: 'Last name is required',
                    account_number_is_required: 'Account number is required',
                    account_number_not_valid: 'A bank account number must be numeric and 16 or 24 characters long',
                    active_disbursement_request_error_title: 'Error',
                    active_disbursement_request_error_desc: 'You already requested a payout at {{payoutRequestDate}}',
                    paylout_limit_error_title: 'Error',
                    paylout_limit_error_desc:
                        'Your balance is under the limit ({{payoutLimit}}). Pending cashbacks become payable after {{payoutDays}} days.',
                },
                translation: {
                    save: 'Save',
                    cancel: 'Cancel',
                    email_is_required: 'Email is required',
                    must_be_valid_email: 'Must be a valid email address',
                    cant_be_empty: 'This field is required',
                    must_be_email: 'Must be a valid e-mail address',
                    password_too_short: 'Password must be at least 8 characters long',
                    password_too_long: 'The password is too long',
                    passwords_dont_match: 'The two passwords do not match',
                    account_already_exists: 'An account with this e-mail already exists',
                    error_general: 'There was an error during your request',
                    saved: 'Saved',
                    bad_auth: 'Wrong e-mail or password',
                    logged_in: 'Logged in successfully',
                    settings_saved: 'Settings updated',
                    error: 'Error',
                    registered: 'Registered successfully',
                    facebook_email_was_not_shared: 'You must share your e-mail address when using Facebook login',
                    email_sent: 'Success! Please check your e-mails for instructions',
                    disbursement_saved: 'Disbursement saved successfully',
                    disbursement_error: 'There was an error saving your request',
                    consent_delete_success: 'Consent deleted',
                    consent_delete_error: 'There was an error during processing your request',
                    contact_request_sent: "Thank you! We'll contact you soon.",
                    contact_request_error: 'There was an error during processing your request',
                    subscribed_to_bank: 'You have subscribed to receiving news about this bank',
                    name_is_required: 'Name is required',
                    message_is_required: 'Message is required',
                },
            },
        },
    });

export default i18n;
