import { Box, Typography, CircularProgress, Stack } from "@mui/material";

export default() => {
    return(
        <Box sx={{m: '24px'}}>
            <Typography variant="h5">Step 1</Typography>
            <Stack direction="row" alignItems="center" gap={1} sx={{marginTop: '12px'}}>
                <CircularProgress size="1em" />
                <Typography variant="body1">Add your consent here</Typography>
            </Stack>
        </Box>
    );
}