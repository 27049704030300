import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, Stack } from '@mui/material';
import CardMedia from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

export default({model, item, clickHandler}) => {
    const fullUrl = model.getImageUrl(item.partnerImage);
    return(
        <Card elevation={3} sx={{display: 'flex', flexDirection: 'column', maxWidth: '200px', justifyContent: "space-between", marginBottom: '5px', marginTop: '5px'}}>
            <CardActionArea onClick={() => clickHandler?.(item.id)} sx={{flexGrow: 1, flexDirection: 'column', alignItems: 'stretch',}}>
                <CardMedia component="img" width="100%" src={fullUrl} alt="logo" />
                <CardContent sx={{flexGrow: 1}}>
                    <Typography variant="body1" component="div" style={{fontSize: '14px'}}>Valid: {item.getShortValidText(model)}</Typography>
                    <Typography variant="body2" component="div">{item.getValueText(model)}</Typography>
                    <Stack sx={{marginTop: 1}} direction="row" spacing={2} alignItems="center">
                        {item.userStatus == 'ACTIVE' ? <CheckIcon color="success" /> : <DoDisturbIcon color="error" />}
                        {item.userStatus == 'ACTIVE' && 
                            <Typography variant="body2" component="div">Active</Typography>
                        }
                        {item.userStatus != 'ACTIVE' && 
                            <Typography variant="body2" component="div">Inactive</Typography>
                        }
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}