import * as React from 'react';
import { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import PartnersBasicCarousel from './ui/PartnersBasicCarousel';
import Input from '@mui/joy/Input';
import SearchRounded from '@mui/icons-material/SearchRounded';
import { partnerStore } from './store/PartnerStore';
import PartnerListViewModel from './model/PartnerListViewModel';
import UserModel from '../../model/UserModel'
import CommonViewModel from '../common/model/CommonViewModel';

export default() => {
    const dispatcher = useDispatch();
    const navigation = useNavigate();
    //Data
    const commonModel = new CommonViewModel();
    const userData = useSelector(state => commonModel.getUser(state));
    const partnerData = useSelector(state => commonModel.getPartnerData(state));
    const forceReload = useSelector(state => commonModel.getForceReload(state));
    const model = new PartnerListViewModel(partnerData, new UserModel(userData));
    useEffect(() => model.getData(dispatcher, forceReload));
    const partnerSearchQuery = useSelector(state => state.partnerStoreReducer.partnerSearchQuery);
    const byCategory = model.getDefaultList(partnerSearchQuery);
    const partnerSearch = (e) => {
        const searched = e.target.value;
        if(searched.length > 1) {
            dispatcher(partnerStore.actions.searchPartners(searched));
        }
        else {
            dispatcher(partnerStore.actions.searchPartners(""));
        }
    }
    let keys = Object.keys(byCategory);
    const partnerClicked = (id) => {
        navigation("/partner/"+id);
    }
    return(
        <div>
            <Input autoFocus placeholder="search" startDecorator={<SearchRounded />} onChange={partnerSearch} defaultValue={partnerSearchQuery} />
            <h2>Partners</h2>
            {
                keys.map(catId => {
                    let categoryTitle = byCategory[catId].title;
                    let categoryItems = byCategory[catId].items;
                    return <PartnersBasicCarousel model={model} key={catId} title={categoryTitle} partners={categoryItems} clickHandler={partnerClicked} />
                })
            }
        </div>
    );
}