import React from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import NavBackItem from './NavBackItem';

const TermsConditionsSettings = () => {
    return (
        <div className='setting-box-size'>
            <div className='setting-navigator'>
                <NavBackItem />
                <Box sx={{ display: 'flex', flexDirection: 'column' }} className='h-space'>
                    <h3 className='title-md '>General Terms and Conditions</h3>
                    <p className='text-light'>Valid from 2019.01.01.</p>
                </Box>
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', textAlign: 'justify' }} className='settings__details-wrapper '>
                <Typography sx={{ fontWeight: '500' }}>
                    {' '}
                    Capitalised terms used in these General Terms and Conditions and not specified elsewhere have the following meanings:
                </Typography>
                <p>
                    API: Application programming interface; documentation of the procedures (services) of a program or system program and their use
                    that may be used by other programs;
                </p>
                <p>Application: shall have the meaning attributed to it in Clause 1.1 of this General Term and Conditions;</p>
                <p>
                    Cashback: a refund of money received by the User through the Application. The Partner's goal is to acquire new customers and
                    retain old customers through a loyalty program provided by Cashback to the User
                </p>
                <p>
                    Company: RECASH Europe Limited Company (European Banking Authority ID: 25434336, represented in the United Kingdom by its wholly
                    owned subsidiary, Recash Application Operating Ltd. with its registered office at 160 Kemp House City Road, London, England, EC1V
                    2NX); Company’s Bank: Transferwise Limited (European Banking Authority ID:900507);
                </p>
                <p>Parties: shall mean the User and the Company individually the “Party”, collectively the “Parties”;</p>
                <p>
                    Partner: a legal entity that has a contractual relationship with the Company and provides a refund of money to the User paying by
                    credit card through the Application;
                </p>
                <p>Partner Transaction: a credit card payment transaction where the User has paid for goods or services at the Partner;</p>
                <p>PSD2: European Union Directive 2015/2366 / EU;</p>

                <p>
                    Push Message: messages that pop up on the User's mobile device from the Application and are specifically intended to inform or
                    prompt the User to take action;
                </p>
            </Box>
        </div>
    );
};

export default TermsConditionsSettings;
