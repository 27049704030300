import { Button, Modal, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { Box } from "@mui/material";
import { bankStore } from '../store/BankStore';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    height: '80%',
    overFlowX: 'scroll',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
};

export default() => {
    const dispatcher = useDispatch();
    const user = useSelector(state => state.loginStoreReducer.user);
    const banks = useSelector(state => state.bankStoreReducer.bankData);
    const selectedBankId = useSelector(state => state.bankStoreReducer.selectedBankForDetails);
    let showModal = false;
    let selectedBankItem = null;
    if(banks) {
        let bankItem = banks.data.banks.filter(i => {
            return i.id === selectedBankId;
        });
        if(bankItem.length > 0) {
            showModal = true;
            selectedBankItem = bankItem[0];
        }
    }
    const onAddConsent = () => {
        if(selectedBankId) {
            dispatcher(bankStore.actions.startConsentProcess(selectedBankId));
        }
    }
    const onCloseDialog = () => {
        dispatcher(bankStore.actions.showBankDetailsDialog(null));
    }
    return(
        <Modal open={showModal} aria-labelledby="modal-modal-title" aria-describedby="Bank details">
            <Box sx={style}>
                <Typography variant="h5" gutterBottom>{selectedBankItem?.name}</Typography>
                {user &&
                <Button onClick={onAddConsent}>Add consent</Button>
                }
                {!user &&
                <p>Please login to add new consent</p>
                }
                <Button variant="contained" sx={{position: 'absolute', bottom: '12px', right: '12px'}} onClick={onCloseDialog}>Close</Button>
            </Box>
        </Modal>
    );
}