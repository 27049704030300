import { Stack } from '@mui/material';
import { Fragment } from 'react';

export default ({ model, item }) => {
    return (
        <Stack direction='row' spacing={2} sx={{ m: 1 }}>
            {item.type == 'CASHBACK' && item.transactionStatus == 'BOOKED' && (
                <Fragment>
                    <img src={item.getPartnerLogo(model)} style={{ width: '50px' }} />
                    <p>{item.name}</p>
                    <p>{item.getAmount(model)}</p>
                </Fragment>
            )}
            {item.type == 'CASHBACK' && item.transactionStatus != 'BOOKED' && (
                <Fragment>
                    <img src={item.getPartnerLogo(model)} style={{ width: '50px' }} />
                    <p>{item.name}</p>
                    <p>{item.transactionStatus}</p>
                </Fragment>
            )}
            {item.type == 'DISBURSEMENT' && (
                <Fragment>
                    <img src={item.getPartnerLogo(model)} style={{ width: '50px' }} />
                    <p>Payout</p>
                    <p>{item.getAmount(model)}</p>
                </Fragment>
            )}
            {item.type == 'TRANSFER_FEE' && (
                <Fragment>
                    <img src={item.getPartnerLogo(model)} style={{ width: '50px' }} />
                    <p>Transfer fee</p>
                    <p>{item.getAmount(model)}</p>
                </Fragment>
            )}
        </Stack>
    );
};
