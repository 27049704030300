import CashbackCoupon from "../../../model/CashbackCoupon";
import PartnerModel from "../../../model/PartnerModel";
import BaseViewModel from "../../../model/viewModel/BaseViewModel";

class CouponViewModel extends BaseViewModel {

    constructor(data, user) {
        super();
        this.user = user;
        this.coupons = [];
        this.partners = [];
        if(data && data.data) {
            this.initPartners(data.data.partners);
            this.initCoupons(data.data.cashbackCoupons);
        }
    }

    initPartners(partnerData) {
        partnerData.forEach(i => {
            this.partners.push(new PartnerModel(i));
        });
    }

    initCoupons(couponData) {
        couponData.forEach(i => {
            this.coupons.push(new CashbackCoupon(i));
        });
    }

    getUser() {
        return this.user;
    }

    getCouponData(id) {
        if(!id) return null;
        let found = this.coupons.filter(i => i.id == id);
        return (found && found.length > 0) ? found.pop() : null;
    }

}

export default CouponViewModel;