import { Box, Typography, Stack } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';

export default() => {
    return(
        <Box sx={{m: '24px'}}>
            <Typography variant="h5">Step 3</Typography>
            <Stack direction="row" alignItems="center" gap={1} sx={{marginTop: '12px'}}>
                <ErrorIcon color="error" fontSize="large" />
                <Typography variant="body1">There was an error during the process. Please close the dialog and start the process again.</Typography>
            </Stack>
        </Box>
    );
}