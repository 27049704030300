import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginNeededComponent from "../common/LoginNeededComponent";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import ConsentCarouselCard from "./ui/ConsentCarouselCard";
import { Button } from "@mui/material";
import NoConsentsComponent from "./ui/NoConsentsComponent";
import CommonViewModel from "../common/model/CommonViewModel";
import ConsentListViewModel from "./ui/model/ConsentListViewModel";
import UserModel from "../../model/UserModel";
import ConsentDetailsDialog from "./ui/ConsentDetailsDialog";
import { useTranslation } from "react-i18next";
import BasicApproveDialog from "../common/BasicApproveDialog";
import { commonActions } from "../../reducer/CommonReducer";

export default() => {
    const { t, i18n } = useTranslation('consents');
    const dispatcher = useDispatch();
    const commonModel = new CommonViewModel();
    const user = useSelector(state => commonModel.getUser(state));
    const consentList = useSelector(state => commonModel.getConsents(state));
    const forceReload = useSelector(state => commonModel.getForceReload(state));
    const model = new ConsentListViewModel(consentList, new UserModel(user));
    const [consentDelete, setConsentDelete] = useState(false);
    const [showConsentDeleteApprove, setConsentDeleteApprove] = useState(false);
    useEffect(() => model.getData(dispatcher, forceReload));
    if(!user) {
        return(<LoginNeededComponent />);
    }
    const consents = model.getDefaultList();

    const onConsentClicked = (data) => {
        setConsentDelete(data);
    }
    const onConsentDelete = (id) => {
        setConsentDeleteApprove(true);
    }
    const onCancelConsentDelete = () => {
        setConsentDelete(false);
        setConsentDeleteApprove(false);
    }
    const onConsentDoDeleteHandler = () => {
        onCancelConsentDelete();
        model.onDeleteConsent(dispatcher, consentDelete?.id).unwrap().then(result => {
            if(result.success) {
                dispatcher(commonActions.notify({type: 'success', message: 'consent_delete_success'}));
            }
            else {
                dispatcher(commonActions.notify({type: 'success', message: 'consent_delete_error'}));
            }
            reloadFull();
        });
    }
    const reloadFull = () => {
        //TODO replace this with useEffect...
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }
    return(
        <Box>
            <h2>Bank consents</h2>
            <Box sx={{marginBottom: '24px', textAlign: 'left'}}>
                <Button variant="contained" onClick={() => model.onAddNewConsent(dispatcher)}>Add new</Button>
            </Box>
            <Box>
            {consents.length > 0 &&
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 8, md: 20 }}>
                        {
                        consents.map((_, index) => (
                        <Grid key={_.id} xs={2} sm={4} md={4}>
                            <ConsentCarouselCard model={model} data={_} clickHandler={onConsentClicked} />
                        </Grid>
                        ))
                        }
                    </Grid>
                </Box>
            }
            {consents.length == 0 &&
            <NoConsentsComponent />
            }
            </Box>
            <ConsentDetailsDialog model={model} show={!!consentDelete} data={consentDelete} onDeleteHandler={onConsentDelete} onCancelHandler={onCancelConsentDelete} />
            <BasicApproveDialog model={model} show={showConsentDeleteApprove} title={t('consent_delete_approve')} onOkHandler={onConsentDoDeleteHandler} onCancelHandler={onCancelConsentDelete} />
        </Box>
    );
}