import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { getPartnersRequest, partnerStore } from '../partners/store/PartnerStore';
import { loginStore } from '../auth/store/LoginStore';
import HomeCouponsComponent from './ui/HomeCouponsComponent';
import HomeFavoritesComponent from './ui/HomeFavoritesComponent';
import HomeNearMeComponent from './ui/HomeNearMeComponent';
import HomePartnersComponent from './ui/HomePartnersComponent';
import HomePromosComponent from './ui/HomePromosComponent';
import HomeViewModel from './model/HomeViewModel';
import UserModel from '../../model/UserModel';
import { ClassNames } from '@emotion/react';

export default () => {
    const navigation = useNavigate();
    const dispatcher = useDispatch();
    //Data
    const userData = useSelector((state) => state.commonReducer.user);
    const userLoggedInReload = useSelector((state) => state.loginStoreReducer.userLoggedInReload);
    const partnerData = useSelector((state) => state.partnerStoreReducer.partnerData);
    useEffect(() => {
        if (!partnerData || userLoggedInReload) {
            dispatcher(getPartnersRequest(userData));
        }
        if (userLoggedInReload) {
            dispatcher(loginStore.actions.resetReloadState());
        }
    });
    const [showPromo, setShowPromo] = useState(null);
    //ViewModel
    const viewModel = new HomeViewModel(partnerData, new UserModel(userData));
    viewModel.setPartnerItemClicked((source, data) => {
        navigation('/partner/' + data);
    });
    viewModel.setPartnerRowButtonClicked((source, data) => {
        navigation('/partners');
    });
    viewModel.setCashbackCouponClicked((data) => {
        dispatcher(partnerStore.actions.showCoupon(data.id));
    });

    return (
        <div className=''>
            <HomePromosComponent model={viewModel} />
            <HomeCouponsComponent model={viewModel} />

            <HomePartnersComponent model={viewModel} />
            <HomeFavoritesComponent model={viewModel} />
            <HomeNearMeComponent model={viewModel} />
        </div>
    );
};
