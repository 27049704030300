import { IconButton, Box, Stack, Typography, Button, Menu, MenuItem } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from 'react-redux';
import { commonActions } from '../../reducer/CommonReducer';
import { Link } from 'react-router-dom';
import { loginStore } from '../auth/store/LoginStore';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { getUserProfileRequest } from '../user/store/UserStore';
import UserProfileViewModel from '../user/model/UserProfileViewModel';
import { CloseIcon } from '@mui/icons-material/Close';
import Login from '../main/Login';

export default () => {
    const dispatcher = useDispatch();
    const navigation = useNavigate();
    //Data
    const user = useSelector((state) => state.commonReducer.user);
    const userProfile = useSelector((state) => state.userStoreReducer.userProfile);
    const profileModel = new UserProfileViewModel(userProfile, user);
    useEffect(() => {
        if (!userProfile) {
            dispatcher(getUserProfileRequest(user));
        }
    });
    let balance = '-';
    if (userProfile && userProfile.data) {
        balance = profileModel.formatCurrency(userProfile?.data?.balance, userProfile?.data?.currency);
    }
    const storedEmail = useSelector((state) => state.loginStoreReducer.userEmail);
    const onLogin = () => {
        dispatcher(commonActions.showLogin(true));
    };
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    /*   const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }; */
    /*  const handleMenuClicked = (id) => {
        switch (id) {
            case 'profile':
                navigation('/profile');
                break;
            case 'contact':
                navigation('/contact');
                break;
            case 'logout':
                dispatcher(commonActions.logout());
                dispatcher(loginStore.actions.logout());
                navigation('/');
                break;
        }
        handleClose();
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
 */

    const showRegister = () => {
        dispatcher(commonActions.showRegister(true));
    };

    const { t, i18n } = useTranslation('menu');

    function handleNavBar() {
        //TOGGLE the mobile navbar
        let navbar = document.querySelector('.nav-bar');
        if (window.innerWidth < 800) {
            //navbreak
            navbar?.classList.toggle('collapsible--expanded');
            let navlogo = document.querySelector('#nav-icon1');
            navlogo?.classList.toggle('open');
        }
        let sidebar = document.querySelector('.sidebar__container');
        if (window.innerWidth < 800) {
            sidebar?.classList.toggle('sidebar-mobile-expanded');
        }
    }
    return (
        <nav className='navbar'>
            <div className='navbar__mobile flex'>
                <Link to='/' className='nav__title'>
                    <img src='recash-temp-logo.webp' alt='logo' className='nav__logo' />
                </Link>
            </div>
            <button className='nav__toggle collapsible' onClick={handleNavBar}>
                <div id='nav-icon1'>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
            <Login mobileAppearance={false} />
        </nav>
    );
};
