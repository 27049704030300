import { Button, Modal, Typography, Stepper, Step, StepLabel, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { bankStore } from "../../../banks/store/BankStore";
import { generateAuthorizationLinkRequest, checkConsentStatusRequest, consentStore } from "../../store/ConsentStore";
import Step1NormalComponent from "./steps/Step1NormalComponent";
import Step1LoadingComponent from "./steps/Step1LoadingComponent";
import Step2NormalComponent from "./steps/Step2NormalComponent";
import Step3NormalComponent from "./steps/Step3NormalComponent";
import ConsentFinishedComponent from "./steps/ConsentFinishedComponent";
import ConsentFinishedWithErrorComponent from "./steps/ConsentFinishedWithErrorComponent";
import { commonActions } from "../../../../reducer/CommonReducer";
import BankViewModel from "../../../banks/model/BankViewModel";
import UserModel from "../../../../model/UserModel";
import ConsentProcessViewModel from "../model/ConsentProcessViewModel";
import { useTranslation } from "react-i18next";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: '90%',
    overFlowX: 'scroll',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
};

const maxAllowedStatusCallsNum = 30;
const statusCallPeriodInSeconds = 5;

let consentStateIntervalCall = null;
let consentStateIntervalCallNum = 0;
let consentProcessWindow = null;

const handleOpeningWindow = (authUrl) => {
    const w = 1024;
    const h = 768;
    const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
    const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);
    consentProcessWindow = window.open(authUrl, null, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`);
}

export default() => {
    const { t, i18n } = useTranslation('consents');
    const dispatcher = useDispatch();
    const [activeStep, setActiveStep] = useState(0);
    const [stepLoading, setStepLoading] = useState(false);
    const [consentFinished, setConsentFinished] = useState(false);
    const [consentFinishedError, setConsentFinishedError] = useState(false);
    const steps = ['Information', 'Redirecting you to your bank', 'Finish'];
    //Data
    const user = useSelector(state => state.commonReducer.user);
    const banks = useSelector(state => state.bankStoreReducer.bankData);
    const bankViewModel = new BankViewModel(banks, new UserModel(user));
    const bankId = useSelector(state => bankViewModel.getSelectedBankForConsent(state));
    const bank = bankViewModel.findBankById(bankId);
    const model = new ConsentProcessViewModel();
    //Consent
    const authorizationLink = useSelector(state => state.consentStoreReducer.authorizationLink);
    const authUrl = authorizationLink?.data?.authorizationUrl;
    const consentStatusData = useSelector(state => state.consentStoreReducer.consentStatus);
    //Close dialog actions
    const closeDialog = () => {
        consentProcessWindow = null;
        consentStateIntervalCallNum = 0;
        setActiveStep(0);
        setStepLoading(false);
        setConsentFinished(false);
        setConsentFinishedError(false);
        dispatcher(consentStore.actions.clearPendingConsent());
        dispatcher(bankStore.actions.startConsentProcess(null));
    }
    //See partners action
    const seePartnersHandler = () => {
        closeDialog();
        dispatcher(commonActions.showTab(1));
    }

    const onConsentFormValidated = (values) => {
        setStepLoading(true);
        dispatcher(generateAuthorizationLinkRequest({
            userData: user, 
            bankId: bank?.id, 
            accountNumber: values.accountNumber, 
            bankLoginId: values.bankLoginId
        }));
    }

    //Next button actions
    const nextAction = () => {
        if(activeStep == 0 && !authorizationLink) {
            document.getElementById("consentForm").requestSubmit(); //TODO probably could be done differently...
        }
        if(activeStep == 1 && authorizationLink) {
            setStepLoading(true);
            setActiveStep(2);
            handleOpeningWindow(authUrl);
        }
        if(activeStep == 2) {
            if(consentStateIntervalCall) {
                clearInterval(consentStateIntervalCall);
            }
            closeDialog();
        }
    }
    if(authorizationLink && stepLoading && activeStep == 0) {
        setStepLoading(false);
        setActiveStep(1);
    }
    if(activeStep == 2 && consentStatusData && consentStatusData.data && !consentFinished) {
        setStepLoading(false);
        clearInterval(consentStateIntervalCall);
        setConsentFinished(true);
        setConsentFinishedError(false);
    }
    let nextButtonText = "Next";
    if(activeStep == 0) {
        nextButtonText = "Start";
    }
    if(activeStep == 2) {
        nextButtonText = "Finish";
    }
    let closeButtonText = "Cancel";
    if(consentFinished) {
        closeButtonText = "Close";
    }
    useEffect(() => {
        if(authorizationLink && activeStep == 2 && !consentFinished) {
            consentStateIntervalCall = setInterval(() => {
                consentStateIntervalCallNum++;
                if(consentStateIntervalCallNum > maxAllowedStatusCallsNum || (consentProcessWindow && consentProcessWindow.closed && !consentFinished)) {
                    setConsentFinished(true);
                    setConsentFinishedError(true);
                    clearInterval(consentStateIntervalCall);
                }
                else {
                    dispatcher(checkConsentStatusRequest({userData: user, consentId: authorizationLink.data.consentId}));
                }
            }, statusCallPeriodInSeconds * 1000);
            return () => {
                clearInterval(consentStateIntervalCall);
            };
        }
      });
    return(
        <Modal open={!!bank} aria-labelledby="modal-modal-title" aria-describedby="Bank selection">
            <Box sx={style}>
                <Typography variant="h5" gutterBottom>Add new consent</Typography>
                <Typography variant="body1">Bank: {bank?.name}</Typography>
                <Stepper sx={{marginTop: '24px'}} activeStep={activeStep}>
                    {steps.map((label, index) => {
                    return (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                    })}
                </Stepper>
                <Box>
                    {activeStep == 0 && !stepLoading && 
                        <Step1NormalComponent model={model} bank={bank} onConsentFormValidated={onConsentFormValidated} />
                    }
                    {activeStep == 0 && stepLoading && <Step1LoadingComponent />}
                    {activeStep == 1 && authUrl && !stepLoading && <Step2NormalComponent />}
                    {activeStep == 2 && authUrl && stepLoading && !consentFinished && <Step3NormalComponent />}
                    {activeStep == 2 && consentFinished && !consentFinishedError && <ConsentFinishedComponent seePartnersHandler={seePartnersHandler} />}
                    {activeStep == 2 && consentFinished && consentFinishedError && <ConsentFinishedWithErrorComponent />}
                </Box>
                
                <div style={{position: 'absolute', bottom: '12px', right: '12px'}}>
                    <Button disabled={stepLoading} variant="contained" onClick={() => nextAction()}>{nextButtonText}</Button>
                    <Button variant="contained" color="secondary" style={{marginLeft: '12px'}} onClick={() => closeDialog()}>{closeButtonText}</Button>
                </div>
            </Box>
        </Modal>
    );
}