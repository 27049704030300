import { useNavigate } from 'react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import HomePromoDialog from './HomePromoDialog';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation, Autoplay, Pagination } from 'swiper';

export default ({ model }) => {
    const { t, i18n } = useTranslation('home');
    const navigation = useNavigate();
    let promoList = model.getDefaultSortedPromoList();
    const [showPromo, setShowPromo] = useState(null);
    const onPromoClicked = (data) => {
        if (data.promoLinkData.isTypeInternalPartner()) {
            navigation('/partner/' + data.promoLinkData.linkId);
        } else if (data.promoLinkData.isTypeInternalDialog()) {
            setShowPromo(data.promoLinkData);
        } else if (data.promoLinkData.isTypeInternalDialog()) {
            setShowPromo(data.promoLinkData);
        } else if (data.promoLinkData.isTypeInternalBank()) {
            navigation('/consents');
        } else if (data.promoLinkData.isTypeExternal()) {
            window.open(data.promoLinkData.getExternalLinkText(i18n.language), '_blank').focus();
        }
    };
    return (
        <Swiper
            spaceBetween={0}
            loop={true}
            pagination={true}
            navigation={false}
            modules={[Pagination, Navigation, Autoplay]}
            slidesPerView={1}
            autoplay={{ delay: 2000, disableOnInteraction: false }}
            className='top-promoSwiper'
        >
            {promoList.map((i, j) => {
                return (
                    <SwiperSlide key={i.getKey()}>
                        <img
                            onClick={() => onPromoClicked(i)}
                            style={{ objectFit: 'contain', width: '100%', cursor: 'pointer' }}
                            src={i.getImageUrl(model)}
                        />
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};
