import CashbackCoupon from "../../../model/CashbackCoupon";
import PartnerModel from "../../../model/PartnerModel";
import PromoModel from "../../../model/PromoModel";
import { saveUserLocationRequest } from "../../user/store/UserStore";
import BaseViewModel from "../../../model/viewModel/BaseViewModel";

class HomeViewModel extends BaseViewModel {

    constructor(data, user) {
        super();
        this.user = user;
        this.partners = [];
        this.promos = [];
        this.coupons = [];
        if(data && data.data) {
            this.initPartners(data.data.partners);
            this.initPromos(data.data.promos);
            this.initCoupons(data.data.cashbackCoupons);
        }
    }

    initPartners(partnerData) {
        partnerData.forEach(i => {
            this.partners.push(new PartnerModel(i));
        });
    }

    initPromos(promoData) {
        promoData.forEach(i => {
            this.promos.push(new PromoModel(i));    
        });
    }

    initCoupons(couponData) {
        couponData.forEach(i => {
            this.coupons.push(new CashbackCoupon(i));
        });
    }

    getUser() {
        return this.user;
    }

    getDefaultSortedPartnerList(limit) {
        let sorted = this.partners.sort((a, b) => {
            if(a.order == 0) return 1;
            else if(b.order == 0) return -1;
            return a.order - b.order;
        });
        return {
            partnerNum: sorted.length, 
            partnerData: sorted.slice(0, limit)
        };
    }

    getDefaultSortedPromoList() {
        let sorted = this.promos.sort((a, b) => {
            if(a.order == 0) return 1;
            else if(b.order == 0) return -1;
            return a.order - b.order;
        });
        return sorted;
    }

    getDefaultSortedFavoriteList() {
        let purchasedList = [];
        let favoritedList = [];
        let mergedFavorites = [];
        let favoriteNum = 0;
        purchasedList = this.partners.filter(i => {
            return (i.favoriteOrder >= 1);
        });
        favoritedList = this.partners.filter(i => {
            return (i.favoriteOrder === 0);
        });
        purchasedList.sort((a, b) => {
        if(a.companyName > b.companyName) {
            return 1;
        }
        else return 1;
        });
        favoritedList.sort((a, b) => {
            if(a.companyName > b.companyName) {
                return 1;
            }
            else return 1;
        });
        mergedFavorites = purchasedList.concat(favoritedList);
        favoriteNum = mergedFavorites.length;
        return {
            partnerNum: favoriteNum,
            partnerData: mergedFavorites
        };
    }

    getDefaultSortedCouponList() {
        return {
            couponNum: this.coupons.length,
            couponData: this.coupons
        };
    }

    getLocationSortedPartnerList(geoloc, limit, limitDistance = 3000) {
        if(geoloc?.error || !geoloc?.latitude || !geoloc?.longitude) {
            return {
                partnerNum: 0,
                partnerData: null
            };
        }
        this.partners.forEach(i => {
            i.getMinDistance(geoloc?.latitude, geoloc?.longitude);
        });
        let sortedList = [];
        this.partners.forEach(i => sortedList.push(i));
        sortedList.sort((a, b) => {
            return a.minDistance - b.minDistance;
        });
        let numOfItems = 0;
        sortedList.forEach(i => {
            if(i.minDistance < limitDistance) {
                numOfItems++;
            }
        });
        limit = (limit > numOfItems) ? numOfItems : limit;
        return {
            partnerNum: numOfItems,
            partnerData: sortedList.slice(0, limit)
        };
    }

    setPromoItemClicked(handler) {
        this.promoItemClickedHandler = handler;
    }

    onPromoItemClicked(data) {
        if(this.promoItemClickedHandler) {
            this.promoItemClickedHandler(data);
        }
    }

    setPartnerItemClicked(handler) {
        this.partnerItemClicked = handler;
    }

    onPartnerItemClicked(source, data) {
        if(this.partnerItemClicked) {
            this.partnerItemClicked(source, data);
        }
    }

    setPartnerRowButtonClicked(handler) {
        this.partnerRowButtonClicked = handler;
    }

    onPartnerRowButtonClicked(source, data) {
        if(this.partnerRowButtonClicked) {
            this.partnerRowButtonClicked(source, data);
        }
    }

    setCashbackCouponClicked(handler) {
        this.cashbackCouponClicked = handler;
    }

    onCashbackCouponClicked(data) {
        if(this.cashbackCouponClicked) {
            this.cashbackCouponClicked(data);
        }
    }  

    saveUserLocation(dispatcher, latitude, longitude) {
        return dispatcher(saveUserLocationRequest({user: this.user, latitude, longitude}));
    }
}

export default HomeViewModel;