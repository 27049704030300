import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import PartnerMapShopComponent from "./PartnerMapShopComponent";

export default({addresses, clickHandler}) => {
    const selectedItem = useSelector(state => state.partnerStoreReducer.selectedAddress);
    const onAddressClicked = (id) => {
        clickHandler?.(id);
    }
    if(!addresses || addresses.length < 1) {
        return "";
    }
    useEffect(() => {
        if(selectedItem) {
            document.querySelector("#address_item_"+selectedItem).scrollIntoView();
        }
    }, [selectedItem]);
    return(
        <Stack sx={{width: '20%', height: '100%', overflowY: 'scroll'}}>
            {addresses && 
                addresses.map(i => {
                    return <PartnerMapShopComponent key={'address_'+i.id} item={i} clickHandler={onAddressClicked} selectedItem={selectedItem} />
                })
            }
        </Stack>
    );
}