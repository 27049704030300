import { favoritePartnerRequest } from "../../../store/PartnerStore";
import { partnerStore } from "../../../store/PartnerStore";

class PartnerDetailViewModel {

    static NO_RESTRICTIONS = 'no_rescrictions';
    static NO_RESTRICTIONS_SHOP = 'no_rescrictions_shop';
    static NO_RESTRICTIONS_WEBSHOP = 'no_rescrictions_webshop';
    static NO_TIME_RESTRICTIONS = 'no_time_rescrictions';
    static SHOP_BUYS = 'shop_buys';
    static WEBSHOP_BUYS = 'webshop_buys';
    static ONLY_SHOP = 'only_shop';
    static ONLY_WEBSHOP = 'only_webshop';
    static ONLY_TIMES = 'only_times';
    static OTHER_TIMES = 'other_times';

    constructor(d, user) {
        this.data = d;
        this.user = user;
    }

    getConditionList() {
        let conditions = [];
        if(!this.data) {
            return conditions;
        }
        const hasTimeLimit = this.data.hasDiscountTimeLimit();
        const shopDiscount = this.data.getShopDiscount();
        const webshopDiscount = this.data.getWebshopDiscount();
        if(!hasTimeLimit) {
            if(shopDiscount && webshopDiscount) {
                if(this.sameDiscounts(shopDiscount, webshopDiscount)) {
                    conditions.push(this.getCondition(PartnerDetailViewModel.NO_RESTRICTIONS, this.getDiscountText(shopDiscount)));
                    conditions.push(this.getCondition(PartnerDetailViewModel.NO_RESTRICTIONS_SHOP));
                    conditions.push(this.getCondition(PartnerDetailViewModel.NO_RESTRICTIONS_WEBSHOP));
                }
                else {
                    conditions.push(this.getCondition(PartnerDetailViewModel.NO_RESTRICTIONS));
                    conditions.push(this.getCondition(PartnerDetailViewModel.SHOP_BUYS, this.getDiscountText(shopDiscount)));
                    conditions.push(this.getCondition(PartnerDetailViewModel.WEBSHOP_BUYS, this.getDiscountText(webshopDiscount)));
                }
            }
            else if(shopDiscount) {
                conditions.push(this.getCondition(PartnerDetailViewModel.NO_TIME_RESTRICTIONS));
                conditions.push(this.getCondition(PartnerDetailViewModel.ONLY_SHOP, this.getDiscountText(shopDiscount)));
            }
            else if(webshopDiscount) {
                conditions.push(this.getCondition(PartnerDetailViewModel.NO_TIME_RESTRICTIONS));
                conditions.push(this.getCondition(PartnerDetailViewModel.ONLY_WEBSHOP, this.getDiscountText(webshopDiscount)));
            }
            else {
                conditions.push(...this.getDailyConditions());
            }
        }
        else {
            conditions.push(...this.getDailyConditions());
            if(shopDiscount && webshopDiscount) {
                if(this.sameDiscounts(shopDiscount, webshopDiscount)) {
                    conditions.push(this.getCondition(PartnerDetailViewModel.NO_RESTRICTIONS_SHOP));
                    conditions.push(this.getCondition(PartnerDetailViewModel.NO_RESTRICTIONS_WEBSHOP));
                }
                else {
                    conditions.push(this.getCondition(PartnerDetailViewModel.SHOP_BUYS, /*this.getDiscountText(shopDiscount)*/null));
                    conditions.push(this.getCondition(PartnerDetailViewModel.WEBSHOP_BUYS, this.getDiscountText(webshopDiscount)));
                }
            }
            else if(shopDiscount) {
                conditions.push(this.getCondition(PartnerDetailViewModel.ONLY_SHOP, /*this.getDiscountText(shopDiscount)*/null));
            }
            else if(webshopDiscount) {
                conditions.push(this.getCondition(PartnerDetailViewModel.ONLY_WEBSHOP, this.getDiscountText(webshopDiscount)));
            }
        }
        return conditions;
    }

    getDailyConditions() {
        let conditions = [];
        if(!this.data) {
            return conditions;
        }
        conditions.push(this.getCondition(PartnerDetailViewModel.ONLY_TIMES))
        this.data.discounts.forEach(i => {
            let from = i.fromHour;
            let to = i.toHour;
            if(to == 0) {
                to = 24;
            }
            let fromTo = from + ":00 - " + to + ":00";
            let discount = i.discount + "%";
            let cond = {
                type: 'daily',
                text: fromTo,
                day: 'day_'+i.dayOfWeek.toLowerCase(),
                percentage: discount
            };
            conditions.push(cond);
        });
        const shopDiscount = this.data.getShopDiscount();
        if(shopDiscount) {
            conditions.push(this.getCondition(PartnerDetailViewModel.OTHER_TIMES, this.getDiscountText(shopDiscount)));
        }
        return conditions;
    }

    sameDiscounts(a, b) {
        return a.discount == b.discount;
    }

    getCondition(type, text = null) {
        return {type: type, text: text};
    }

    getDiscountText(discount) {
        return discount.discount;
    }

    favoritePartner(dispatcher, partnerId) {
        dispatcher(favoritePartnerRequest({user: this.user, partnerId}));
        dispatcher(partnerStore.actions.updateFavoriteStatus(partnerId));
    }

    getMappableAddresses() {
        return this.data?.addresses.filter(i => {
            return (i.lat && i.lng);
        });
    }

}

export default PartnerDetailViewModel;