import { Box, Button, Stack, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "../../reducer/CommonReducer";
import CommonViewModel from "../common/model/CommonViewModel";
import ContactViewModel from "./model/ContactViewModel";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export default() => {
    const { t, i18n } = useTranslation('translation');
    const dispatcher = useDispatch();
    let commonModel = new CommonViewModel();
    const userData = useSelector(state => commonModel.getUser(state));
    const model = new ContactViewModel(userData);

    const formik = useFormik({
        initialValues: {
            contactEmail: '',
            contactName: '',
            contactMessage:''
        },
        validationSchema: Yup.object({
            contactEmail: Yup.string().max(255).required(t('email_is_required')).email(t('must_be_valid_email')),
            contactName: Yup.string().max(255).required(t('name_is_required')),
            contactMessage: Yup.string().max(255).required(t('message_is_required'))
        }),
        onSubmit: values => {
            const {contactEmail, contactName, contactMessage} = values;
            model.onSendContactRequest(dispatcher, contactEmail, contactName, contactMessage).unwrap().then(result => {
                //TODO empty form or reload etc.
                if(result.success) {
                    dispatcher(commonActions.notify({type: 'success', message: 'contact_request_sent'}));
                    reloadFull();
                }
                else {
                    dispatcher(commonActions.notify({type: 'error', message: 'contact_request_error'}));
                }
            });
        },
    });

    const reloadFull = () => {
        //TODO replace this with useEffect...
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

    return(
        <Box>
            <h4>Contact us</h4>
            <Box sx={{ width: '30%' }}>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={2}>
                <TextField 
                    label="E-mail" 
                    id="contactEmail"
                    name="contactEmail"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.contactEmail}
                    error={!!formik.errors.contactEmail} 
                    helperText={formik.errors.contactEmail}
                    color="primary" />
                <TextField 
                    label="Name" 
                    id="contactName"
                    name="contactName"
                    onChange={formik.handleChange}
                    value={formik.values.contactName}
                    error={!!formik.errors.contactName} 
                    helperText={formik.errors.contactName}
                    color="primary" />
                <TextField 
                    label="Message" 
                    id="contactMessage"
                    name="contactMessage"
                    multiline
                    rows={10}
                    onChange={formik.handleChange}
                    value={formik.values.contactMessage}
                    error={!!formik.errors.contactMessage} 
                    helperText={formik.errors.contactMessage}
                    color="primary" />
                    <Button variant="contained" type="submit">Send</Button>
                </Stack>
            </form>
            </Box>
        </Box>
    );
}