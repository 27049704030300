import { GeoUtils } from "../util/GeoUtils";

class PartnerAddress {

    constructor(d) {
        return Object.assign(this, d);
    }

    getDistance(lat, lng) {
        return GeoUtils.calculateDistance(lat, lng, this.lat, this.lng);
    }

}

export default PartnerAddress;