import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Button, CardActionArea } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CommonViewModel from '../../common/model/CommonViewModel';
import UserModel from '../../../model/UserModel';
import { useEffect } from 'react';
import BankViewModel from '../model/BankViewModel';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
};

export default() => {
    const dispatcher = useDispatch();
    const commonModel = new CommonViewModel();
    const user = useSelector(state => commonModel.getUser(state));
    const banks = useSelector(state => commonModel.getBankData(state));
    const model = new BankViewModel(banks, new UserModel(user));
    const showModal = useSelector(state => model.getShowBanksDialog(state));
    useEffect(() => model.getBankData(dispatcher, false));
    const bankList = model.getSelectableForConsentList();
    return(
        <Modal open={showModal} aria-labelledby="modal-modal-title" aria-describedby="Bank selection">
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                Please select a bank
                </Typography>
                <Box sx={{ width: '100%', marginTop: '24px', maxHeight: '50vh', overflowY: 'scroll'}}>
                    <Stack spacing={2} sx={{}}>
                        {
                            bankList.map(item => {
                                return (<Card key={item.id}>
                                    <CardActionArea onClick={() => model.onStartConsentProcess(item.id, dispatcher)}>
                                        <CardContent>
                                        <Typography component="div">
                                            {item.name}
                                        </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>);
                            })
                        }
                    </Stack>
                </Box>
                <Box sx={{paddingTop: '24px', m: 'auto', maxWidth: '120px'}}>
                    <Button variant="contained" onClick={() => model.onHideBankDialog(dispatcher)}>Cancel</Button>
                </Box>
            </Box>
        </Modal>
    );
}