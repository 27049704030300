import PartnerAddress from "./PartnerAddress";

class PartnerModel {

    constructor(d) {
        let it = Object.assign(this, d);
        let addressData = [];
        d?.addresses.forEach(i => {
            addressData.push(new PartnerAddress(i));
        });
        it.addresses = addressData;
        it.coupons = [];
        return it;
    }

    getShortDiscountText() {
        let minDiscount = Number.MAX_VALUE;
        let maxDiscount = 0;
        this.discounts.forEach(i => {
            if(i.discount < minDiscount) {
                minDiscount = i.discount;
            }
            if(i.discount > maxDiscount) {
                maxDiscount = i.discount;
            }
        });
        this.globalDiscounts.forEach(i => {
            if(i.discount < minDiscount) {
                minDiscount = i.discount;
            }
            if(i.discount > maxDiscount) {
                maxDiscount = i.discount;
            }
        });
        if (minDiscount == maxDiscount) return minDiscount + "%";
        return minDiscount + "-" + maxDiscount + "%";
    }

    hasCoupon() {
        return this.coupons.length > 0;
    }

    setCoupon(c) {
        this.coupons.push(c);
    }

    hasDiscountTimeLimit() {
        let res = this.discounts.filter(i => {
            return (i.fromHour > 0 && i.toHour < 24);
        });
        return res.length > 0;
    }

    isShop() {
        return this.addresses.length > 0;
    }

    isWebShop() {
        return this.webshopUrl;
    }

    getShopDiscount() {
        return this.globalDiscounts.find(i => {
            return i.type == 'SHOP';
        });
    }

    getWebshopDiscount() {
        return this.globalDiscounts.find(i => {
            return i.type == 'WEBSHOP';
        });
    }

    getMinDistance(lat, lng) {
        let minDistance = Number.MAX_VALUE;
        this.addresses.forEach(i => {
            let dis = i.getDistance(lat, lng);
            if(dis < minDistance) {
                minDistance = dis;
            }
        });
        this.minDistance = minDistance;
        return minDistance;
    }

    getLogo(viewModel) {
        return viewModel.getImageUrl(this.logo);
    }

    search(query) {
        const toSearch = query.trim().toLowerCase();
        return (this.companyName.toLowerCase().includes(toSearch) || this.description.toLowerCase().includes(toSearch));
    }

}

export default PartnerModel;