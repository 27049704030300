import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { commonActions } from '../../reducer/CommonReducer';

import { Box, Button } from '@mui/material';

import { getUserProfileRequest } from '../user/store/UserStore';
import UserProfileViewModel from '../user/model/UserProfileViewModel';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { loginStore } from '../auth/store/LoginStore';

const Login = ({ mobileAppearance }) => {
    const user = useSelector((state) => state.commonReducer.user);
    const dispatcher = useDispatch();
    const navigation = useNavigate();

    const storedEmail = useSelector((state) => state.loginStoreReducer.userEmail);
    const onLogin = () => {
        dispatcher(commonActions.showLogin(true));
    };
    const showRegister = () => {
        dispatcher(commonActions.showRegister(true));
    };
    const { t, i18n } = useTranslation('menu');

    const handleLogout = () => {
        dispatcher(commonActions.logout());
        dispatcher(loginStore.actions.logout());
        navigation('/');
    };
    return (
        <div className={` ${mobileAppearance ? 'login__mobile' : 'login__desktop'}`}>
            {user && storedEmail && (
                <>
                    {/* <Typography variant='body2'>Balance: {balance}</Typography>
                    <Typography variant='body2'>{storedEmail}</Typography>
                    <Box>
                        <IconButton
                            id='user-menu-button'
                            sx={{ minHeight: 0, minWidth: 0, padding: 0 }}
                            aria-label='Account'
                            size='large'
                            onClick={handleClick}
                        >
                            <AccountCircleIcon sx={{ width: '36px', height: '36px' }} />
                        </IconButton>
                        <Menu
                            id='user-menu'
                            aria-labelledby='user-menu-button'
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        >
                            <MenuItem onClick={() => handleMenuClicked('profile')}>{t('profile')}</MenuItem>
                            <MenuItem onClick={() => handleMenuClicked('contact')}>{t('contact_us')}</MenuItem>
                            <MenuItem onClick={() => handleMenuClicked('logout')}>{t('logout')}</MenuItem>
                        </Menu>
                    </Box> */}
                    <Box className='login__buttons' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '18px' }}>
                        <button className={`notification__button flex ${mobileAppearance ? 'btn btn__login' : null} `}>
                            <svg
                                className='w-6 h-6 text-gray-800 dark:text-white not__icon'
                                aria-hidden='true'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='currentColor'
                                viewBox='0 0 20 20'
                            >
                                <path d='M15.133 10.632v-1.8a5.406 5.406 0 0 0-4.154-5.262.955.955 0 0 0 .021-.106V1.1a1 1 0 0 0-2 0v2.364a.946.946 0 0 0 .021.106 5.406 5.406 0 0 0-4.154 5.262v1.8C4.867 13.018 3 13.614 3 14.807 3 15.4 3 16 3.538 16h12.924C17 16 17 15.4 17 14.807c0-1.193-1.867-1.789-1.867-4.175ZM4 4a1 1 0 0 1-.707-.293l-1-1a1 1 0 0 1 1.414-1.414l1 1A1 1 0 0 1 4 4ZM2 8H1a1 1 0 0 1 0-2h1a1 1 0 1 1 0 2Zm14-4a1 1 0 0 1-.707-1.707l1-1a1 1 0 1 1 1.414 1.414l-1 1A1 1 0 0 1 16 4Zm3 4h-1a1 1 0 1 1 0-2h1a1 1 0 1 1 0 2ZM6.823 17a3.453 3.453 0 0 0 6.354 0H6.823Z' />
                            </svg>{' '}
                            <p>{t('notifications')}</p>
                        </button>
                        <Button onClick={handleLogout} className='btn  btn__logout '>
                            <svg
                                className='w-6 h-6 text-gray-800 dark:text-white'
                                aria-hidden='true'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='currentColor'
                                viewBox='0 0 20 20'
                            >
                                <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
                            </svg>
                            {t('logout')}
                        </Button>
                    </Box>
                </>
            )}
            {!user && (
                <>
                    <div className='login__buttons flex'>
                        <Button className='btn btn__login ' onClick={onLogin}>
                            <svg className='w-6 h-6 text-gray-800 dark:text-white' aria-hidden='true' fill='currentColor' viewBox='0 0 20 20'>
                                <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
                            </svg>
                            {t('login')}
                        </Button>

                        <Button onClick={showRegister} className='btn btn__login '>
                            <svg
                                className='w-6 h-6 text-gray-800 dark:text-white'
                                aria-hidden='true'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='currentColor'
                                viewBox='0 0 20 20'
                            >
                                <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
                            </svg>
                            Register
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Login;
