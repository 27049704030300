import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import BankCarouselCard from './BankCarouselCard';

export default({model, banks, clickHandler}) => {
    const cardClicked = (id) => {
        clickHandler?.(id);
    }
    return(
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 4, sm: 8, md: 20 }}>
                {
                banks.map((_, index) => (
                <Grid xs={2} sm={4} md={4} key={index}>
                    <BankCarouselCard model={model} item={_} clickHandler={cardClicked} />
                </Grid>
                ))
                }
            </Grid>
        </Box>
    );
}