import { Box, Button, Modal, Stack, Typography } from "@mui/material";

const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: 'auto',
    overFlowX: 'scroll',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
};

export default({model, show, title, onOkHandler, onCancelHandler}) => {
    return (
        <Modal open={show} aria-labelledby="modal-modal-title" aria-describedby="Approve dialog">
            <Box sx={style}>
                <Stack spacing={2} direction="column">
                    <Typography>{title}</Typography>
                    <Button variant="contained" color="error" onClick={onOkHandler}>Ok</Button>
                    <Button variant="contained" onClick={onCancelHandler}>Cancel</Button>
                </Stack>
            </Box>
        </Modal>
    )
}