import * as React from 'react';
import Box from '@mui/material/Box';
import { Tab, Tabs } from '@mui/material';
import { Route, Routes, useLocation } from 'react-router';
import HomeComponent from '../home/HomeComponent';
import { BrowserRouter, Link } from 'react-router-dom';
import BanksComponent from '../banks/BanksComponent';
import PartnersComponent from '../partners/PartnersComponent';
import ConsentsComponent from '../consents/ConsentsComponent';
import TransactionsComponent from '../transactions/TransactionsComponent';
import PartnerDetailComponent from '../partners/ui/detail/PartnerDetailComponent';

import ProfileComponent from '../user/ProfileComponent';
import ChangePasswordSettings from '../user/ChangePasswordSettings';
import FaqSettings from '../user/FaqSettings';
import ContactUsSettings from '../user/ContactUsSettings';
import AboutUsSettings from '../user/AboutUsSettings';
import TermsConditionsSettings from '../user/TermsConditionsSettings';

import { useTranslation } from 'react-i18next';
import InviteComponent from '../invite/InviteComponent';
import { useDispatch } from 'react-redux';
import { getApiStatusRequest } from '../user/store/UserStore';
import ContactComponent from '../contact/ContactComponent';
import Login from '../main/Login';

const Main2Component = () => {
    const dispatcher = useDispatch();
    const pathName = useLocation();
    let path = pathName.pathname;
    if (path.match('/partner/')) {
        path = '/partners';
    } else if (path.match('/profile')) {
        path = '/';
    } else if (path.match('/contact')) {
        path = '/';
    }
    const { t, i18n } = useTranslation('menu');
    const [siteEnabled, setSiteEnabled] = React.useState(true);
    dispatcher(getApiStatusRequest())
        .unwrap()
        .then((result) => {
            if (result.success) {
                setSiteEnabled(!result.data?.underMaintenance);
            }
        });

    const sidebarItems = [
        {
            title: t('home'),
            path: '/',
            icon: (
                <svg
                    className='w-6 h-6 text-gray-800 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 20'
                >
                    <path
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M3 8v10a1 1 0 0 0 1 1h4v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5h4a1 1 0 0 0 1-1V8M1 10l9-9 9 9'
                    />
                </svg>
            ),
        },
        {
            title: 'My balance',
            path: '/transactions',
            icon: (
                <svg
                    className='w-6 h-6 text-gray-800 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 20'
                >
                    <path
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M3 8v10a1 1 0 0 0 1 1h4v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5h4a1 1 0 0 0 1-1V8M1 10l9-9 9 9'
                    />
                </svg>
            ),
        },
        {
            title: 'My linked banks',
            path: '/consents',
            icon: (
                <svg
                    className='w-6 h-6 text-gray-800 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 20'
                >
                    <path
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M3 8v10a1 1 0 0 0 1 1h4v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5h4a1 1 0 0 0 1-1V8M1 10l9-9 9 9'
                    />
                </svg>
            ),
        },
        {
            title: 'Search',
            path: '/partners',
            icon: (
                <svg
                    className='w-6 h-6 text-gray-800 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 20'
                >
                    <path
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M3 8v10a1 1 0 0 0 1 1h4v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5h4a1 1 0 0 0 1-1V8M1 10l9-9 9 9'
                    />
                </svg>
            ),
        },
        {
            title: 'Refer a friend',
            path: '/invite',
            icon: (
                <svg
                    className='w-6 h-6 text-gray-800 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 20'
                >
                    <path
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M3 8v10a1 1 0 0 0 1 1h4v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5h4a1 1 0 0 0 1-1V8M1 10l9-9 9 9'
                    />
                </svg>
            ),
        },
        {
            title: 'Profile',
            path: '/profile',
            icon: (
                <svg
                    className='w-6 h-6 text-gray-800 dark:text-white'
                    aria-hidden='true'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 20 20'
                >
                    <path
                        stroke='currentColor'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M3 8v10a1 1 0 0 0 1 1h4v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5h4a1 1 0 0 0 1-1V8M1 10l9-9 9 9'
                    />
                </svg>
            ),
        },
    ];
    const handleMobileSideBar = () => {
        if (window.innerWidth < 800) {
            //sidebar
            let sidebar = document.querySelector('.sidebar__container');
            sidebar?.classList.toggle('sidebar-mobile-expanded');
            //navlogo
            let navlogo = document.querySelector('#nav-icon1');
            navlogo?.classList.toggle('open');
        }
    };

    return (
        <>
            {/*       <Tabs className='mainTabs' value={path}>
                <Tab label={t('home')} value='/' to='/' component={Link} />
                <Tab label={t('partners')} value='/partners' to='/partners' component={Link} />
                <Tab label={t('banks')} value='/banks' to='/banks' component={Link} />
                <Tab label={t('consents')} value='/consents' to='/consents' component={Link} />
                <Tab label={t('transactions')} value='/transactions' to='/transactions' component={Link} />
                <Tab label={t('invite')} value='/invite' to='/invite' component={Link} />
                <Tab label={t('help')} value='/help' to='/help' component={Link} />
                <Tab label={t('about')} value='/about' to='/about' component={Link} />
            </Tabs> */}
            <Box sx={{ display: 'flex', minHeight: '100vh' }}>
                <div className='sidebar__container'>
                    <div className='sidebar'>
                        {sidebarItems.map((menu, index) => (
                            <Link
                                key={index}
                                onClick={handleMobileSideBar}
                                to={menu.path}
                                className={`sidebar__item ${pathName.pathname === menu.path ? 'active__menu' : null}`}
                            >
                                {menu.icon}
                                {menu.title}
                            </Link>
                        ))}
                        <Login mobileAppearance={true} />
                    </div>
                </div>
                <section className='container'>
                    <Box sx={{ p: 2, width: '100%' }}>
                        {siteEnabled && (
                            <Routes>
                                <Route path='/' element={<HomeComponent />} />
                                <Route path='/partners' element={<PartnersComponent />} />
                                <Route path='/partner/:id' element={<PartnerDetailComponent />} />
                                <Route path='/banks' element={<BanksComponent />} />
                                <Route path='/consents' element={<ConsentsComponent />} />
                                <Route path='/transactions' element={<TransactionsComponent />} />
                                <Route path='/invite' element={<InviteComponent />} />
                                <Route path='/profile' element={<ProfileComponent />} />
                                <Route path='/profile/change-password' element={<ChangePasswordSettings />} />
                                <Route path='/profile/faq' element={<FaqSettings />} />
                                <Route path='/profile/about-us' element={<AboutUsSettings />} />
                                <Route path='/profile/contact-us' element={<ContactUsSettings />} />
                                <Route path='/profile/terms-and-conditions' element={<TermsConditionsSettings />} />
                                <Route path='/contact' element={<ContactComponent />} />
                            </Routes>
                        )}
                        {!siteEnabled && <p>Under maintenance</p>}
                    </Box>
                </section>
            </Box>
        </>
    );
};

export default Main2Component;
