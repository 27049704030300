class CashbackModel {

    constructor(d) {
        return Object.assign(this, d);
    }

    getPartnerLogo(viewModel) {
        return viewModel.getImageUrl(this.logo);
    }

    getAmount(viewModel) {
        return viewModel.formatCurrency(this.amount, this.currency);
    }

}

export default CashbackModel;