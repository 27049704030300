import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import CardMedia from '@mui/material/Card';
import Typography from '@mui/material/Typography';

export default({model, item, clickHandler}) => {
    return(
        <Card sx={{display: 'flex', flexDirection: 'column'}}>
            <CardActionArea onClick={() => clickHandler?.(item.id)} sx={{flexGrow: 1, flexDirection: 'column', alignItems: 'stretch'}}>
                <CardMedia component="img" width="100%" src={item.getImage(model)} alt="logo" />
                <CardContent sx={{flexGrow: 1}}>
                    <Typography variant="body1" component="div">{item.name}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}