import { useDispatch, useSelector } from "react-redux";
import { Box, Button, FormControlLabel, FormGroup, Modal, Switch } from '@mui/material';
import CommonViewModel from '../common/model/CommonViewModel';
import { changeCouponStatusRequest, partnerStore } from '../partners/store/PartnerStore';
import UserModel from '../../model/UserModel';
import CouponViewModel from './model/CouponViewModel';
import { useState } from 'react';
import { commonActions } from '../../reducer/CommonReducer';

export default() => {
    const [isActive, setActive] = useState(false);
    const [loaded, isLoaded] = useState(false);
    const dispatcher = useDispatch();
    const commonModel = new CommonViewModel();
    const user = useSelector(state => commonModel.getUser(state));
    const partnerData = useSelector(state => commonModel.getPartnerData(state));
    const couponViewModel = new CouponViewModel(partnerData, new UserModel(user));
    const selectedCouponId = useSelector(state => commonModel.getCouponData(state));
    const couponData = couponViewModel.getCouponData(selectedCouponId);
    if(couponData && !loaded) {
        setActive((couponData.userStatus == "ACTIVE") ? true : false);
        isLoaded(true);
    }
    const changeActiveStatus = () => {
        setActive(!isActive);
        dispatcher(changeCouponStatusRequest({user, couponId: couponData.id})).unwrap().then((res) => {
            dispatcher(commonActions.notify({type: 'success', message: 'saved'}));
            dispatcher(partnerStore.actions.updateCouponStatus(couponData.id));
        }, (e) => {});
    }
    const open = (selectedCouponId != null);
    const closeDialog = () => {
        dispatcher(partnerStore.actions.showCoupon(null));
        isLoaded(false);
    }
    return(
        <Modal open={open}>
            <Box sx={{p: '16px', position: 'absolute', top: '50%', left: '50%', background:'white', transform: 'translate(-50%,-50%)', minWidth: '50%'}}>
                <h4>Coupon details</h4>
                <img src={couponData?.getPartnerLogo(couponViewModel)} style={{width: '10%'}}/>
                <p>{couponData?.partnerName}</p>
                <p>{couponData?.validFrom} - {couponData?.validTo}</p>
                <p>{couponData?.getValueText(couponViewModel)}</p>
                <Button variant="contained" onClick={closeDialog}>Close</Button>
                <Box sx={{position: 'absolute', right: 0, top: 0, marginEnd: '16px', marginTop: '16px'}}>
                    <FormGroup style={{display: 'inline-block'}}>
                        <FormControlLabel control={<Switch checked={isActive} onChange={changeActiveStatus} />} label="Active" />
                    </FormGroup>
                </Box>
            </Box>
        </Modal>
    );
}