import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SiteApi from "../../../rest/SiteApi";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const loginRequest = createAsyncThunk("auth/login", async (payload) => {
    const {loginEmail, loginPassword} = payload;
    const result = await SiteApi.login(loginEmail, loginPassword);
    return result.data;
});

const loginWithFacebookRequest = createAsyncThunk("auth/loginFacebook", async (payload) => {
    const result = await SiteApi.loginFacebook(payload);
    return result.data;
});

const forgetPasswordRequest = createAsyncThunk("auth/forget-password", async (payload) => {
    const {email, lang} = payload;
    const result = await SiteApi.forgetPassword(email, lang);
    return result.data;
});

const registerRequest = createAsyncThunk("auth/register", async (payload) => {
    const {email, password, region} = payload;
    const result = await SiteApi.register(email, password, region);
    return result.data;
});

const loginStore = createSlice({
    name: "login",
    initialState: {
        user: null,
        userEmail: null,
        userLoggedInReload: false,
        lastLocationSaved: null
    },
    reducers: {
        storeEmail: (state, action) => {
            state.userEmail = action.payload;
        },
        resetReloadState: (state, action) => {
            state.userLoggedInReload = false;
        },
        saveLocation: (state, action) => {
            state.lastLocationSaved = action.payload;
        },
        logout: (state, action) => {
            state.user = null;
            state.userEmail = null;
            state.userLoggedInReload = true;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginRequest.fulfilled, (state, action) => {
            if(action.payload.success) {
                state.user = action.payload.data;
                state.userLoggedInReload = true;
            }
        });
        builder.addCase(loginWithFacebookRequest.fulfilled, (state, action) => {
            if(action.payload.success) {
                state.user = action.payload.data;
                state.userLoggedInReload = true;
            }
        });
        builder.addCase(registerRequest.fulfilled, (state, action) => {
            if(action.payload.success) {
                state.user = action.payload.data;
                state.userLoggedInReload = true;
            }
        });
    }
});

const loginReducerConfig = {
    key: 'loginStoreReducer',
    storage: storage,
    blacklist: []
};

const loginStoreReducer = persistReducer(loginReducerConfig, loginStore.reducer);


export {
    loginStore, loginStoreReducer, loginRequest, loginWithFacebookRequest, forgetPasswordRequest, registerRequest
}
