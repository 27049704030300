import CashbackModel from "../../../model/CashbackModel";
import BaseViewModel from "../../../model/viewModel/BaseViewModel";
import { requestDisbursementRequest } from "../../user/store/UserStore";
import { getTransactionsRequest } from "../store/TransactionStore";

class TransactionListViewModel extends BaseViewModel {

    constructor(data, user) {
        super();
        this.data = data;
        this.user = user;
        this.cashbacks = [];
        if(this.data) {
            this.initCashbacks(this.data?.data?.transactions);
        }
    }

    getData(dispatcher) {
        if(!this.data && this.user?.exists()) {
            dispatcher(getTransactionsRequest(this.user));
        }
    }

    initCashbacks(data) {
        data?.forEach(i => {
            this.cashbacks.push(new CashbackModel(i));
        });
    }

    getDefaultList() {
        return this.cashbacks;
    }

    getPayableList() {
        return this.cashbacks.filter(i => {
            return (i.payable && i.type == "CASHBACK" && i.transactionStatus == "BOOKED");
        });
    }

    getPendingList() {
        return this.cashbacks.filter(i => {
            return (!i.payable && i.type == "CASHBACK" && i.transactionStatus == "BOOKED");
        });
    }

    canRequestDisbursement() {
        return this.data?.data?.canRequestDisbursement;
    }

    getActiveDisbursement() {
        return this.data?.data?.activeDisbursement;
    }

    getActiveDisbursementRequestedDate() {
        return this.formatDate(this.data?.data?.activeDisbursement?.createdAt, {month: "2-digit", day: "2-digit"});
    }

    getRequestLimit() {
        return this.formatCurrency(this.data?.data?.requestLimit, this.data?.data?.requestLimitCurrency);
    }

    getPayoutPendingDays() {
        return this.data?.data?.pendingDays;
    }

    sendDisbursementRequest(dispatcher, firstName, lastName, accountNumber, swiftCode) {
        return dispatcher(requestDisbursementRequest({user: this.user, firstName, lastName, accountNumber, swiftCode}));
    }

}

export default TransactionListViewModel;