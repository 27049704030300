import BankSelectDialog from "../banks/ui/BankSelectDialog";
import ConsentProcessDialog from "../consents/ui/process/ConsentProcessDialog";
import { Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { commonActions } from "../../reducer/CommonReducer";
import { useTranslation } from "react-i18next";
import MuiAlert from '@mui/material/Alert';
import { forwardRef } from "react";
import CouponDetailsDialog from "../coupons/CouponDetailsDialog";
import RegisterDialog from "../auth/RegisterDialog";
import LoginDialog from "../auth/LoginDialog";
import ForgetPasswordDialog from "../auth/ForgetPasswordDialog";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default() => {
    const { t, i18n } = useTranslation('translation');
    const dispatcher = useDispatch();
    const notification = useSelector(state => state.commonReducer.notification);
    if(notification) {
        console.log("notification", notification);
    }
    const open = (notification && notification.message?.length > 0);
    let notificationType = "info";
    if(notification) {
        notificationType = notification.type;
    }
    const closeNotification = () => {
        dispatcher(commonActions.notified());
    }
    return(
        <div>
            <LoginDialog />
            <RegisterDialog />
            <ForgetPasswordDialog />
            <BankSelectDialog />
            <ConsentProcessDialog />
            <CouponDetailsDialog />
            <Snackbar open={open} autoHideDuration={3000} onClose={() => closeNotification()}>
                <Alert severity={notificationType} sx={{ width: '100%' }}>
                    {t(notification?.message)}
                </Alert>
            </Snackbar>
        </div>
    );
}