import UserRefferalUser from "../../../model/UserReferralUser";
import BaseViewModel from "../../../model/viewModel/BaseViewModel";
import { getUseFriendsRequest } from "../../user/store/UserStore";

class InviteViewModel extends BaseViewModel {

    constructor(data, user) {
        super();
        this.data = data;
        this.user = user;
        this.friends = [];
        data?.data?.users.forEach(i => {
            this.friends.push(new UserRefferalUser(i));
        });
    }

    getData(dispatcher) {
        if(!this.data && this.user?.exists()) {
            dispatcher(getUseFriendsRequest(this.user));
        }
    }

    getDefaultList() {
        return this.friends;
    }

}

export default InviteViewModel;