import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Box, CardActionArea } from '@mui/material';
import CardMedia from '@mui/material/Card';
import Typography from '@mui/material/Typography';

export default({item, selectedItem, clickHandler}) => {
    return(
        <Box id={'address_item_'+item.id}>
            <Card elevation={3} sx={{background: (selectedItem == item.id) ? '#ffef73' : 'white', display: 'flex', flexDirection: 'column',minHeight: '100px', justifyContent: "space-between", marginBottom: '5px', marginTop: '5px'}}>
                <CardActionArea onClick={() => clickHandler?.(item.id)} sx={{flexGrow: 1, flexDirection: 'column', alignItems: 'stretch',}}>
                    <CardContent sx={{flexGrow: 1}}>
                        <Typography variant="body1" component="div" style={{fontSize: '14px'}}>{item.shopName}</Typography>
                        <Typography variant="body2" component="div">{item.fullAddress}</Typography>
                        <Typography variant="body2" component="div">{item.contactPhone}</Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Box>
    );
}