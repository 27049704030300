import React, { useEffect, useRef, ReactElement } from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Box, Stack } from "@mui/material";
import PartnerMapShopComponent from "./PartnerMapShopComponent";
import { useState } from "react";
import PartnerMapShopListComponent from "./PartnerMapShopListComponent";
import { useDispatch } from "react-redux";
import { partnerStore } from "../../store/PartnerStore";

let map = null;

const render = (status)=> {
    if (status == 'LOADING') return <h3>{status} ..</h3>;
    if (status == 'FAILURE') return <h3>{status} ...</h3>;
    return null;
  };
  
function MyMapComponent({center, zoom, mapItem, setMap}) {
    const ref = useRef();
    useEffect(() => {
        if(!mapItem) {
            setMap(new window.google.maps.Map(ref.current, {center, zoom}));
        }
    }, [mapItem]);
    return <div ref={ref} id="map" style={{width: '100%', height: '100%'}} />;
}

export default({model}) => {
    const dispatcher = useDispatch();
    const [markers, setMarkers] = useState(null);
    const [map, setMap] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const addresses = model.getMappableAddresses();
    const center = { lat: 47, lng: 19 };
    const zoom = 7;
    useEffect(() => {
        if(!markers && window.google && map) {
            const markerItems = [];
            addresses.forEach(i => {
                let m = new window.google.maps.Marker();
                m.setOptions({
                    position: {lat: i.lat, lng: i.lng},
                    addressId: i.id
                });
                m.addListener("click", (e) => {
                    dispatcher(partnerStore.actions.showAddress(i.id));
                    map.setCenter(m.position);
                    map.setZoom(15);
                });
                m.setMap(map);
                markerItems.push(m);
            });
            setMarkers(markerItems);  
        }
        return () => {
            markers?.forEach(i => i.setMap(null));
        };
    }, [markers, window.google, map]);
    const onAddressClicked = (id) => {
        dispatcher(partnerStore.actions.showAddress(id));
        const m = markers?.filter(i => i['addressId'] == id).pop();
        map.setCenter(m.position);
        map.setZoom(15);
    }
    return (
        <Stack direction="row" sx={{width: '100%', height: '100%'}}>
            <Box sx={{width: '80%', height: '100%'}}>
                <Wrapper apiKey="AIzaSyC61Nyw8C9MqJcUAnOG0eRLRaE7DOdVEgc" render={render}>
                    <MyMapComponent center={center} zoom={zoom} mapItem={map} setMap={setMap} />
                </Wrapper>
            </Box>
            <PartnerMapShopListComponent addresses={addresses} clickHandler={onAddressClicked} />
        </Stack>
    );
}