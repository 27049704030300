class UserRefferalUser {

    constructor(d) {
        return Object.assign(this, d);
    }

    isAccepted() {
        return this.status == "ACCEPTED";
    }

}

export default UserRefferalUser;