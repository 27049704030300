import { config as apiConfig, selectedProfile } from '../../config/config';

class BaseViewModel {

    constructor() {
        this.hufCurrency = new Intl.NumberFormat('hu-HU', {
            style: 'currency',
            currency: 'HUF',
        });
        this.usdCurrency = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        this.eurCurrency = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
        });
    }

    isEmail(email) {
        if(!email) {
            return false;
        }
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

    formatDate(dt, options) {
        if(!dt) {
            return "-";
        }
        if(!options) {
            options = {dateStyle: "full"};
        }
        var date = new Date(dt);
        return date.toLocaleString('hu-HU', options); //TODO language
    }
    
    formatCurrency(amount, currency) {
        if(!currency) {
            return "-";
        }
        if(isNaN(amount)) {
            return "-";
        }
        switch(currency) {
            default: return amount+' '+currency;
            case "HUF": return this.hufCurrency.format(amount);
            case "USD": return this.usdCurrency.format(amount);
            case "EUR": return this.eurCurrency.format(amount);
        }
    }

    formatConsentStatus(t, status) {
        if(!status) {
            return "-";
        }
        return t('consent_status_'+status.toLowerCase());
    }

    getImageUrl(path) {
        if(!path) {
            return null;
        }
        if(path.startsWith("/") || path.startsWith('\\')) {
            path = path.substring(1, path.length);
        }
        return apiConfig[selectedProfile].STATIC_API_BASE_URL+"/"+path;
    }

    getImageUrlFromId(path) {
        if(!path) {
            return null;
        }
        return apiConfig[selectedProfile].STATIC_API_BASE_URL+"/image/"+path;
    }

    stringSplitter(myString, chunkSize) {
        let splitString = [];
        for (let i = 0; i < myString.length; i = i + chunkSize) {
            splitString.push(myString.slice(i, i + chunkSize));
        }
        return splitString;
    }

}

export default BaseViewModel;