import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SiteApi from "../../../rest/SiteApi";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const getBanksRequest = createAsyncThunk("banks/list", async (userData) => {
    let token = (userData && userData.access_token) ? userData.access_token : null;
    const result = await SiteApi.getBanks();
    return result.data;
});
const bankStore = createSlice({
    name: "banks",
    initialState: {
        bankData: null,
        bankSearchQuery: "",
        showBankDialog: false,
        selectedBankForConsent: null,
        selectedBankForDetails: null
    },
    reducers: {
        searchBanks: (state, action) => {
            state.bankSearchQuery = action.payload;
        },
        showBankDialog: (state, action) => {
            state.showBankDialog = action.payload;
        },
        showBankDetailsDialog: (state, action) => {
            state.selectedBankForDetails = action.payload;
        },
        startConsentProcess: (state, action) => {
            state.showBankDialog = false;
            state.selectedBankForConsent = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getBanksRequest.fulfilled, (state, action) => {
            console.log("Get banks result: ",action.payload);
            state.bankData = action.payload;
        });
    }
});

const bankReducerConfig = {
    key: 'bankStoreReducer',
    storage: storage,
    blacklist: ['bankData', 'bankSearchQuery', 'showBankDialog', 'selectedBankForConsent', 'selectedBankForDetails']
};

const bankStoreReducer = persistReducer(bankReducerConfig, bankStore.reducer);

export {
    bankStore, bankStoreReducer, getBanksRequest
}
