class BankModel {

    constructor(d) {
        return Object.assign(this, d);
    }

    search(query) {
        return (this.name && query && this.name.trim().toLowerCase().includes(query.trim().toLowerCase()));
    }

    getImage(viewModel) {
        return viewModel.getImageUrl(this.logo.url);
    }

}

export default BankModel;