import BaseViewModel from "../../../model/viewModel/BaseViewModel";
import { sendContactRequest } from "../../user/store/UserStore";

class ContactViewModel extends BaseViewModel {

    constructor(user) {
        super();
        this.user = user;
    }

    onSendContactRequest(dispatcher, email, name, message) {
        return dispatcher(sendContactRequest({user: this.user, email, name, message}));
    } 

}

export default ContactViewModel;