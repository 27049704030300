import { Box, Typography, Stack, TextField } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";

export default({model, bank, onConsentFormValidated}) => {
    const { t, i18n } = useTranslation('consents');
    const requiresAccount = bank?.requiresAccount;
    const requiresBankLoginId = bank?.requiresBankLoginId;

    const formik = useFormik({
        initialValues: {
          accountNumber: '',
          bankLoginId: ''
        },
        validationSchema: Yup.object().shape({
            accountNumber: Yup.string()
                .test('isRequired', t('account_number_required'), val => {
                    if(!requiresAccount) return true;
                    return val;
                })
                .test('isValidAccountNumber', t('account_number_not_numeric'), val => {
                    if(!requiresAccount) return true;
                    return val && val.match(/^\d+$/);
                })
                .test('isAccountNumberProperLength', t('account_number_length'), val => {
                    if(!requiresAccount) return true;
                    return val && (val.length == 16 || val.length == 24);
                }),
            bankLoginId: Yup.string()
                .min(6, t('bank_login_id_short'))
                .test('isRequired', t('bank_login_id_required'), val => {
                    if(!requiresBankLoginId) return true;
                    return val;
                })
        }),
        onSubmit: values => {
            onConsentFormValidated(values);
        },
    });

    return(
        <Box sx={{m: '24px'}}>
            <Typography variant="h5">Step 1</Typography>
            <Stack direction="row" alignItems="center" gap={1} sx={{marginTop: '12px'}}>
                <InfoIcon color="info" fontSize="large" />
                <Typography variant="body1">Add your consent here 2</Typography>
            </Stack>
            <form id="consentForm" onSubmit={formik.handleSubmit}>
            <Stack spacing={2} sx={{marginTop: 2, maxWidth: '400px'}}>
                {requiresAccount && 
                <TextField 
                    label="Account number" 
                    id="accountNumber"
                    name="accountNumber"
                    onChange={formik.handleChange}
                    value={formik.values.accountNumber}
                    error={!!formik.errors.accountNumber} 
                    helperText={formik.errors.accountNumber}
                    color="primary" />
                }
                {requiresBankLoginId && 
                <TextField 
                    label="Direkt ID" 
                    id="bankLoginId"
                    name="bankLoginId"
                    onChange={formik.handleChange}
                    value={formik.values.bankLoginId}
                    error={!!formik.errors.bankLoginId} 
                    helperText={formik.errors.bankLoginId}
                    color="primary" />
                }
            </Stack>
            </form>
        </Box>
    );
}