class UserModel {

    constructor(d) {
        return Object.assign(this, d);
    }

    exists() {
        return this.access_token != null;
    }

}

export default UserModel;