import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import CardMedia from '@mui/material/Card';
import Typography from '@mui/material/Typography';

export default({model, item, clickHandler}) => {
    let minDistance = item.minDistance;
    let fullUrl = model.getImageUrl(item.logo);
    if(minDistance && minDistance >= Number.MAX_VALUE) {
        minDistance = "n.a.";
    }
    return(
        <Card elevation={3} sx={{display: 'flex', flexDirection: 'column',minHeight: '320px', justifyContent: "space-between", marginBottom: '5px', marginTop: '5px'}}>
            <CardActionArea onClick={() => clickHandler?.(item.id)} sx={{flexGrow: 1, flexDirection: 'column', alignItems: 'stretch',}}>
                <CardMedia component="img" width="100%" src={fullUrl} alt="logo" />
                <CardContent sx={{flexGrow: 1}}>
                    <Typography variant="body1" component="div" style={{fontSize: '14px'}}>{item.companyName}</Typography>
                    <Typography variant="body2" component="div">{item.getShortDiscountText()}</Typography>
                    {minDistance &&
                    <Typography variant="body2" component="div">{minDistance} m</Typography>
                    }
                </CardContent>
            </CardActionArea>
        </Card>
    );
}