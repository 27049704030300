import BankModel from "../../../model/BankModel";
import BaseViewModel from "../../../model/viewModel/BaseViewModel";
import { subscribeToBankRequest } from "../../user/store/UserStore";
import { bankStore, getBanksRequest } from "../store/BankStore";

class BankViewModel extends BaseViewModel {

    constructor(data, user) {
        super();
        this.user = user;
        this.data = data;
        this.banks = [];
        if(data && data.data) {
            this.initBanks(data.data.banks);
        }
    }

    getBankData(dispatcher, forceReload = false) {
        if(!this.data || forceReload) {
            dispatcher(getBanksRequest(this.user));
        }
    }

    initBanks(bankData) {
        bankData.forEach(i => {
            this.banks.push(new BankModel(i));
        });
    }

    getDefaultList(searchQuery) {
        let sortedList = [];
        this.banks.forEach(i => {
            if(searchQuery) {
                if(i.search(searchQuery)) {
                    sortedList.push(i);
                }
            }
            else {
                sortedList.push(i);
            }
        });
        sortedList.sort((a, b) => {
            if(a.name > b.name) return 1;
            else return -1;
        });
        return sortedList;
    }

    getSelectableForConsentList() {
        return this.banks.filter(i => i.status == "ACTIVE");
    }

    onBankSearched(e, dispatcher) {
        const searched = e.target.value;
        if(searched.length > 1) {
            dispatcher(bankStore.actions.searchBanks(searched.trim()));
        }
        else {
            dispatcher(bankStore.actions.searchBanks(""));
        }
    }

    onBankClicked(id, dispatcher) {
        dispatcher(bankStore.actions.showBankDetailsDialog(id));
    }

    getShowBanksDialog(state) {
        return state.bankStoreReducer.showBankDialog;
    }

    onHideBankDialog(dispatcher) {
        dispatcher(bankStore.actions.showBankDialog(false));
    }

    onStartConsentProcess(id, dispatcher) {
        dispatcher(bankStore.actions.startConsentProcess(id));
    }

    getSelectedBankForConsent(state) {
        return state.bankStoreReducer.selectedBankForConsent;
    }

    findBankById(bankId) {
        let found = this.banks.filter(i => {
            return i.id === bankId;
        });
        if(found && found.length > 0) {
            return found[0];
        }
        return null;
    }

    onSubscribeToBank(dispatcher, bankId, email) {
        return dispatcher(subscribeToBankRequest({user: this.user, bankId, email}));
    } 
}

export default BankViewModel;