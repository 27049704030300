import * as React from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from '../../reducer/CommonReducer';
import { useTranslation } from 'react-i18next';
import ForgetPasswordViewModel from './model/ForgetPasswordViewModel';
import { forgetPasswordRequest } from './store/LoginStore';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

const ForgetPasswordDialog = () => {
    const { t, i18n } = useTranslation(['auth', 'translation']);
    const dispatcher = useDispatch();
    const showModal = useSelector((state) => state.commonReducer.showForgetPasswordForm);
    const model = new ForgetPasswordViewModel();

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().max(255).required(t('email_is_required')).email(t('must_be_valid_email')),
        }),
        onSubmit: (values) => {
            dispatcher(forgetPasswordRequest({ email: values.email, lang: i18n.language == 'hu' ? 'hu' : 'en' }))
                .unwrap()
                .then(
                    (res) => {
                        if (res.success) {
                            dispatcher(commonActions.showForgetPassword(false));
                            dispatcher(commonActions.notify({ type: 'success', message: 'email_sent' }));
                        }
                    },
                    (e) => {}
                );
        },
    });

    const hideModal = () => {
        dispatcher(commonActions.showForgetPassword(false));
    };
    return (
        <Modal open={showModal}>
            <div className='login__box'>
                <Link to='/'>
                    <img src='recash-temp-logo.webp' alt='logo' className='nav__logo' />
                </Link>
                <h2 id='basic-modal-dialog-title'>Forgotten password</h2>
                <Typography id='basic-modal-dialog-description' mt={3} className='login__text'>
                    Please enter your e-mail address. We'll send you an e-mail with the instructions to reset your password.
                </Typography>
                <form onSubmit={formik.handleSubmit} className='login__form'>
                    <TextField
                        label='E-mail'
                        id='email'
                        name='email'
                        type='email'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={!!formik.errors.email}
                        helperText={formik.errors.email}
                        color='primary'
                        className='login__input'
                        sx={{ my: { xs: 4, sm: 8 } }}
                    />
                    <Button variant='contained' type='submit'>
                        Send me verification link
                    </Button>
                    {/*  <Button variant='contained' onClick={hideModal}>
                            Cancel
                        </Button> */}
                    <CloseIcon onClick={hideModal} className='btn--close' />
                </form>
            </div>
        </Modal>
    );
};

export default ForgetPasswordDialog;
