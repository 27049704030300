import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SiteApi from "../../../rest/SiteApi";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const getTransactionsRequest = createAsyncThunk("transactions/list", async (userData) => {
    const result = await SiteApi.getTransactions(userData.access_token);
    return result.data;
});

const transactionStore = createSlice({
    name: "transactions",
    initialState: {
        transactions: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTransactionsRequest.fulfilled, (state, action) => {
            console.log("Get transactions result: ",action.payload);
            state.transactions = action.payload;
        });
    }
});

const transactionReducerConfig = {
    key: 'transactionStoreReducer',
    storage: storage,
    blacklist: ['transactions']
};

const transactionStoreReducer = persistReducer(transactionReducerConfig, transactionStore.reducer);

export {
    transactionStore, transactionStoreReducer, getTransactionsRequest
}
