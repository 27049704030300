import { getDistance } from 'geolib'

class GeoUtilsC {

    calculateDistance(lat, lng, lat2, lng2) {
        if(!lat || !lng || !lat2 || !lng2) {
            return Number.MAX_VALUE;
        }
        return getDistance({ latitude: lat, longitude: lng }, { latitude: lat2, longitude: lng2 });
    }

}

const GeoUtils = new GeoUtilsC();

export {
    GeoUtils
}