class CashbackCoupon {

    constructor(d) {
        return Object.assign(this, d);
    }

    getValueText(viewModel) {
        if(this.valueType == "FIXED") {
            return viewModel.formatCurrency(this.amount, this.currency);
        }
        return this.amount+" %";
    }
    
    getPartnerLogo(viewModel) {
        return viewModel.getImageUrl(this.partnerLogo);
    }

    getShortValidText(viewModel) {
        const dateOptions = {month: "2-digit", day: "2-digit"};
        return viewModel.formatDate(this.validFrom, dateOptions) + " - " + viewModel.formatDate(this.validTo, dateOptions);
    }
}

export default CashbackCoupon;