import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    FormControlLabel,
    Modal,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from '../../reducer/CommonReducer';
import RegisterViewModel from './model/RegisterViewModel';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import CommonViewModel from '../common/model/CommonViewModel';
import { useEffect } from 'react';
import { loginStore, registerRequest } from './store/LoginStore';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

const RegisterDialog = () => {
    const { t, i18n } = useTranslation(['auth', 'translation']);
    const dispatcher = useDispatch();

    const [currentRegion, setCurrentRegion] = useState('hu');
    const commonModel = new CommonViewModel();
    const model = new RegisterViewModel();
    const showModal = useSelector((state) => state.commonReducer.showRegisterForm);
    const regions = useSelector((state) => commonModel.getRegions(state));
    useEffect(() => commonModel.loadRegions(dispatcher, showModal && !regions, null));

    const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            passwordAgain: '',
            region: 'hu',
        },
        validationSchema: Yup.object({
            email: Yup.string().max(255).required(t('email_is_required')).email(t('must_be_valid_email')),
            password: Yup.string()
                .max(255)
                .required(t('password_is_required'))
                .min(8, t('must_be_min_8_characters'))
                .matches(passwordRules, { message: t('password_complexity_error') }),
            passwordAgain: Yup.string()
                .max(255)
                .required(t('password_again_is_required'))
                .oneOf([Yup.ref('password'), null], t('password_match_error')),
            region: Yup.string().required('Region is required'),
        }),
        onSubmit: (values) => {
            const { email, password, region } = values;
            dispatcher(registerRequest({ email, password, region }))
                .unwrap()
                .then(
                    (res) => {
                        if (res.success) {
                            dispatcher(loginStore.actions.storeEmail(email));
                            dispatcher(commonActions.notify({ type: 'success', message: 'registered' }));
                            dispatcher(commonActions.showRegister(false));
                            dispatcher(commonActions.showLogin(false));
                        } else {
                            if (res.errorCode == 'auth-0008') {
                                dispatcher(commonActions.notify({ type: 'error', message: 'account_already_exists' }));
                            } else {
                                dispatcher(commonActions.notify({ type: 'error', message: 'error_general' }));
                            }
                        }
                    },
                    (e) => {}
                );
        },
    });

    let regionList = regions?.data?.regions.filter((i) => {
        return i.id != 'global';
    });
    const onRegioChanged = (e) => {
        setCurrentRegion(e.target.value);
    };
    const hideModal = () => {
        dispatcher(commonActions.showRegister(false));
    };
    return (
        <Modal open={showModal}>
            <div className='login__box'>
                <Link to='/'>
                    <img src='recash-temp-logo.webp' alt='logo' className='nav__logo' />
                </Link>
                <h2 id='basic-modal-dialog-title'>{/* {t('register')} */}Create account </h2>
                <Typography id='basic-modal-dialog-description' mt={3} mb={5} color='text.tertiary' className='login__text'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. quis perferendis, assumenda incidunt deserunt.
                </Typography>
                <form onSubmit={formik.handleSubmit} className='login__form'>
                    <TextField
                        label='E-mail'
                        id='email'
                        name='email'
                        type='email'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={!!formik.errors.email}
                        helperText={formik.errors.email}
                        color='primary'
                        className='login__input'
                        sx={{ mb: { xs: 1, sm: 2 } }}
                    />
                    <TextField
                        label='Password'
                        id='password'
                        name='password'
                        type='password'
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        error={!!formik.errors.password}
                        helperText={formik.errors.password}
                        color='primary'
                        className='login__input'
                        sx={{ mb: { xs: 1, sm: 2 } }}
                    />
                    <TextField
                        label='Password again'
                        id='passwordAgain'
                        name='passwordAgain'
                        type='password'
                        onChange={formik.handleChange}
                        value={formik.values.passwordAgain}
                        error={!!formik.errors.passwordAgain}
                        helperText={formik.errors.passwordAgain}
                        color='primary'
                        className='login__input'
                        sx={{ mb: { xs: 1, sm: 2 } }}
                    />
                    <FormControl fullWidth sx={{ textAlign: 'left' }}>
                        <InputLabel id='demo-simple-select-label'>Region</InputLabel>
                        <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            value={formik.values.region}
                            label='Region'
                            onChange={(event) => {
                                formik.setFieldValue('region', event.target.value);
                            }}
                        >
                            {regionList &&
                                regionList.map((i) => {
                                    return (
                                        <MenuItem key={'region_' + i.id} value={i.id}>
                                            {t('region_' + i.id)}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                    {/*             <FormControl>
                        <RadioGroup
                            id='region'
                            value={formik.values.region}
                            onChange={(event) => {
                                formik.setFieldValue('region', event.target.value);
                            }}
                            name='regions-group'
                        >
                            {regionList &&
                                regionList.map((i) => {
                                    return <FormControlLabel key={'region_' + i.id} value={i.id} control={<Radio />} label={t('region_' + i.id)} />;
                                })}
                        </RadioGroup>
                    </FormControl> */}
                    <Box sx={{ display: 'flex', ml: 5, mt: 2, mb: 1, gap: '20px', justifyContent: 'flex-start' }}>
                        <input type='checkbox' />
                        <p>
                            I accept the{' '}
                            <a href='' className='text-blue text-underlined '>
                                Terms and Conditions
                            </a>
                        </p>
                    </Box>
                    <Box sx={{ display: 'flex', ml: 5, mb: 3, gap: '20px', justifyContent: 'flex-start' }}>
                        <input type='checkbox' />
                        <p>
                            I accept the{' '}
                            <a href='' className='text-blue text-underlined '>
                                Privacy Policy
                            </a>
                        </p>
                    </Box>

                    <CloseIcon onClick={hideModal} className='btn--close' />
                    <Button variant='contained' type='submit'>
                        {/* {t('save', { ns: 'translation' })} */} Create Account
                    </Button>
                    {/*  <Button variant='contained' onClick={hideModal}>
                        {t('cancel', { ns: 'translation' })}
                    </Button> */}
                </form>
            </div>
        </Modal>
    );
};

export default RegisterDialog;
