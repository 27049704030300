import { Avatar } from "@mui/material";
import { Stack } from "@mui/material";

export default({title, titleNum}) => {
    return(
        <Stack direction="row" spacing={1} alignItems="center" sx={{marginBottom: 1}}>
            <h4>{title}</h4>
            <Avatar sx={{ width: 24, height: 24, fontSize:12}}>{titleNum}</Avatar>
        </Stack>
    );
}