import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SiteApi from "../../../rest/SiteApi";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const getPartnersRequest = createAsyncThunk("partners/list", async (userData) => {
    let token = (userData && userData.access_token) ? userData.access_token : null;
    const result = await SiteApi.getPartners(token);
    return result.data;
});

const favoritePartnerRequest = createAsyncThunk("partners/favorite", async (payload) => {
    const{user, partnerId} = payload;
    let token = (user && user.access_token) ? user.access_token : null;
    const result = await SiteApi.favoritePartner(token, partnerId);
    return result.data;
});

const changeCouponStatusRequest = createAsyncThunk("coupons/status", async (payload) => {
    const{user, couponId} = payload;
    let token = (user && user.access_token) ? user.access_token : null;
    const result = await SiteApi.changeCouponStatus(token, couponId);
    return result.data;
});

const partnerStore = createSlice({
    name: "partners",
    initialState: {
        partnerData: null,
        partnerSearchQuery: "",
        selectedCoupon: null,
        selectedAddress: null
    },
    reducers: {
        showCoupon: (state, action) => {
            state.selectedCoupon = action.payload;
        },
        showAddress: (state, action) => {
            state.selectedAddress = action.payload;
        },
        searchPartners: (state, action) => {
            state.partnerSearchQuery = action.payload;
        },
        updateFavoriteStatus: (state, action) => {
            const partnerId = action.payload;
            const toUpdate = state.partnerData?.data?.partners.find(i => {
                return i.id === partnerId; 
            })
            if(toUpdate) {
                toUpdate.favorite = !toUpdate.favorite;
            }
        },
        updateCouponStatus: (state, action) => {
            const couponId = action.payload;
            const toUpdate = state.partnerData?.data?.cashbackCoupons.find(i => {
                return i.id === couponId; 
            });
            if(toUpdate) {
                if(toUpdate.userStatus == "ACTIVE") {
                    toUpdate.userStatus = "INACTIVE";
                }
                else {
                    toUpdate.userStatus = "ACTIVE";
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPartnersRequest.fulfilled, (state, action) => {
            console.log("Get partners result: ",action.payload);
            state.partnerData = action.payload;
        });
        builder.addCase(favoritePartnerRequest.fulfilled, (state, action) => {
            console.log("Favorite partner result: ",action.payload);
        });
        builder.addCase(changeCouponStatusRequest.fulfilled, (state, action) => {
            console.log("Change coupon status result: ",action.payload);
        });
    }
});

const partnerReducerConfig = {
    key: 'partnerStoreReducer',
    storage: storage,
    blacklist: ['partnerData', 'partnerSearchQuery', 'selectedCoupon', 'selectedAddress']
};

const partnerStoreReducer = persistReducer(partnerReducerConfig, partnerStore.reducer);


export {
    partnerStore, partnerStoreReducer, getPartnersRequest, favoritePartnerRequest, changeCouponStatusRequest
}
