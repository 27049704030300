import AccountAccessConsentModel from "../../../../model/AccountAccessConsentModel";
import { checkConsentStatusRequest, deleteConsentRequest, getConsentsRequest } from "../../store/ConsentStore";
import { bankStore } from "../../../banks/store/BankStore";
import BaseViewModel from "../../../../model/viewModel/BaseViewModel";

class ConsentListViewModel extends BaseViewModel {

    constructor(data, user) {
        super();
        this.user = user;
        this.data = data;
        this.consents = [];
        if(data && data.data) {
            this.initConsents(data.data.accountAccessConsents);
        }
    }

    getData(dispatcher) {
        if(!this.data && this.user.exists()) {
            dispatcher(getConsentsRequest(this.user));
        }
    }

    initConsents(consentData) {
        consentData.forEach(i => {
            this.consents.push(new AccountAccessConsentModel(i));
        });
    }

    getDefaultList() {
        return this.consents;
    }

    onAddNewConsent(dispatcher) {
        dispatcher(bankStore.actions.showBankDialog(true));
    }

    onDeleteConsent(dispatcher, consentId) {
        return dispatcher(deleteConsentRequest({user: this.user, consentId}));
    }
}

export default ConsentListViewModel;