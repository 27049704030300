import { BrowserRouter } from 'react-router-dom';
import './App.css';
import StoreProvider from './store/StoreProvider';
import Main2Component from './ui/main/Main2Component';
import ModalsComponent from './ui/main/ModalsComponent';
import UserMenuComponent from './ui/menu/UserMenuComponent';

String.prototype.truncString = function (max, add) {
    add = add || '...';
    return this.length > max ? this.substring(0, max) + add : this;
};

Object.sortObjectByValue = function (obj, sortFunction) {
    if (!sortFunction) {
        sortFunction = Object.customSortOrder;
    }
    let arr = [];
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            arr.push({ key: parseInt(prop), value: obj[prop] });
        }
    }
    arr.sort(sortFunction);
    return arr;
};

Object.customSortOrder = function (a, b) {
    if (a.value == 0) return b.value;
    else if (b.value == 0) return a.value;
    return a.value - b.value;
};

function App() {
    return (
        <StoreProvider>
            <div className='App'>
                <BrowserRouter>
                    <UserMenuComponent />
                    <Main2Component />
                    <ModalsComponent />
                </BrowserRouter>
            </div>
        </StoreProvider>
    );
}

export default App;
