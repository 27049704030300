import * as React from 'react';
import { Button } from '@mui/material';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { Stack } from '@mui/joy';

export default({show, title, text, clickHandler}) => {
    return(
        <Modal open={show}>
            <ModalDialog sx={{background: 'white'}}>
                <Stack spacing={2}>
                    {title && 
                    <p>{title}</p>
                    }
                    <p>{text}</p>
                    <Button onClick={clickHandler}>Close</Button>
                </Stack>
            </ModalDialog>
        </Modal>
    );
}