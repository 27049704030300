import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import CardMedia from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

export default({model, data, clickHandler}) => {
    const { t, i18n } = useTranslation('consents');
    const {id, dateAdded, status, bank} = data;
    const fullUrl = model.getImageUrl(bank.logo.url);
    return(
        <Card sx={{height: "100%", display: "flex", alignItems: "center"}}>
            <CardActionArea onClick={() => clickHandler?.(data)}>
                <CardMedia component="img" height="100" image={fullUrl} src={fullUrl} alt="logo" />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">{bank.name}</Typography>
                    <Typography variant="body2" color="text.secondary" sx={{mt: 1}}>{model.formatDate(dateAdded)}</Typography>
                    <Typography variant="body2" color="text.secondary">{model.formatConsentStatus(t, status)}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}