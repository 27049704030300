import React, { useState } from 'react';
import NavBackItem from './NavBackItem';
import { Box, TextField, Button, Container, Typography } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const ContactUsSettings = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Itt tudsz dolgozni az űrlap adatokkal, például elküldeni az adatokat a szerverre
        console.log('Név:', name);
        console.log('Email cím:', email);
        console.log('Üzenet:', message);
    };
    return (
        <div className='setting-box-size'>
            <div className='setting-navigator'>
                <NavBackItem />
                <h3 className='title-md h-space'>Contact us</h3>
            </div>
            <div className='settings__details-wrapper'>
                <Typography sx={{ mt: 2 }}>Do you want to get in touch with us? Write an email!</Typography>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ display: 'flex', gap: { xs: 0, sm: '10px' }, flexDirection: { xs: 'column', sm: 'row' } }}>
                        <TextField
                            label='Név'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                        <TextField
                            label='Email cím'
                            variant='outlined'
                            fullWidth
                            margin='normal'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </Box>
                    <TextField
                        label='Üzenet'
                        variant='outlined'
                        fullWidth
                        margin='normal'
                        multiline
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        sx={{ mb: 4 }}
                    />
                    <Button className='btn btn--primary' sx={{ width: '300px' }}>
                        Send e-mail
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default ContactUsSettings;
