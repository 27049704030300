import { useTranslation } from "react-i18next";
import BasicAvatarTitle from "../../common/BasicAvatarTitle";
import PartnersBasicCarousel from "../../partners/ui/PartnersBasicCarousel";

export default({model}) => {
    const { t, i18n } = useTranslation('home');
    const user = model.getUser();
    const {partnerNum, partnerData} = model.getDefaultSortedFavoriteList();
    return(
        <div>
            <BasicAvatarTitle title={t('favorites')} titleNum={partnerNum} />
            {user.exists() && (partnerData.length > 0) && 
                <PartnersBasicCarousel 
                    model={model} 
                    key="favoriteList" 
                    partners={partnerData} 
                    clickHandler={(data) => model.onPartnerItemClicked('HomeFavoritesComponent', data)} 
                    buttonClickHandler={(data) => model.onPartnerRowButtonClicked('HomePartnHomeFavoritesComponentersComponent', data)} />
            }
            {user.exists() && (partnerData.length == 0) && 
                <p>{t('no_favorites')}</p>
            }
            {!user.exists() &&
            <p>{t('please_log_in_favorites')}</p>
            }
        </div>
    );
}