import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SiteApi from "../../../rest/SiteApi";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const getConsentsRequest = createAsyncThunk("consents/list", async (userData) => {
    const result = await SiteApi.getConsents(userData.access_token);
    return result.data;
});
const generateAuthorizationLinkRequest = createAsyncThunk("consents/authorize", async (payload) => {
    const {userData, bankId, accountNumber, bankLoginId} = payload;
    const result = await SiteApi.generateAuthorizationLink(userData.access_token, bankId, accountNumber, bankLoginId);
    return result.data;
});
const checkConsentStatusRequest = createAsyncThunk("consents/status", async (payload) => {
    const {userData, consentId} = payload;
    const result = await SiteApi.checkConsentStatus(userData.access_token, consentId);
    return result.data;
});
const deleteConsentRequest = createAsyncThunk("consents/delete", async (payload) => {
    const {user, consentId} = payload;
    const result = await SiteApi.deleteConsent(user.access_token, consentId);
    return result.data;
});
const consentStore = createSlice({
    name: "consents",
    initialState: {
        "consentList": null,
        "authorizationLink": null,
        "consentStatus": null
    },
    reducers: {
        clearPendingConsent: (state, action) => {
            state.authorizationLink = null;
            state.consentStatus = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getConsentsRequest.fulfilled, (state, action) => {
            console.log("Get consents result: ",action.payload);
            state.consentList = action.payload;
        });
        builder.addCase(generateAuthorizationLinkRequest.fulfilled, (state, action) => {
            console.log("Get authorization link result: ",action.payload);
            state.authorizationLink = action.payload;
        });
        builder.addCase(checkConsentStatusRequest.fulfilled, (state, action) => {
            console.log("Consent status link result: ",action.payload);
            state.consentStatus = action.payload;
        });
        builder.addCase(deleteConsentRequest.fulfilled, (state, action) => {
            console.log("Delete consent result: ",action.payload);
        });
    }
});

const consentReducerConfig = {
    key: 'consentStoreReducer',
    storage: storage,
    blacklist: ['consentList', 'authorizationLink', 'consentStatus']
};

const consentStoreReducer = persistReducer(consentReducerConfig, consentStore.reducer);

export {
    consentStore, consentStoreReducer, getConsentsRequest, generateAuthorizationLinkRequest, checkConsentStatusRequest, deleteConsentRequest
}
