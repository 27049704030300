import { Box, Typography, Stack } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

export default() => {
    return(
        <Box sx={{m: '24px'}}>
            <Typography variant="h5">Step 2</Typography>
            <Stack direction="row" alignItems="center" gap={1} sx={{marginTop: '12px'}}>
                <InfoIcon color="info" fontSize="large" />
                <Typography variant="body1">Click next to start the process. The button will open a new window. Please do not close the RECASH website while going through the process.</Typography>
            </Stack>
        </Box>
    );
}