import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SiteApi from "../../../rest/SiteApi";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const getRegionsRequest = createAsyncThunk("users/regions", async (userData) => {
    const result = await SiteApi.getRegions(userData?.access_token);
    return result.data;
});

const getUserProfileRequest = createAsyncThunk("users/profile", async (userData) => {
    const result = await SiteApi.getProfile(userData.access_token);
    return result.data;
});

const getUseFriendsRequest = createAsyncThunk("users/friends", async (userData) => {
    const result = await SiteApi.getUserFriends(userData.access_token);
    return result.data;
});

const getUserSettingsRequest = createAsyncThunk("users/settings", async (userData) => {
    const result = await SiteApi.getUserSettings(userData.access_token);
    return result.data;
});

const saveUserSettingsRequest = createAsyncThunk("users/settings/save", async (payload) => {
    const {user, data} = payload;
    const result = await SiteApi.saveUserSettings(user.access_token, data);
    return result.data;
});

const changePasswordRequest = createAsyncThunk("users/change-password", async (payload) => {
    const {user, oldPassword, newPassword} = payload;
    const result = await SiteApi.changePassword(user.access_token, oldPassword, newPassword);
    return result.data;
});

const deleteAccountRequest = createAsyncThunk("users/account-delete", async (userData) => {
    const result = await SiteApi.deleteAccount(userData.access_token);
    return result.data;
});

const saveUserLocationRequest = createAsyncThunk("users/location", async (payload) => {
    const {user, latitude, longitude} = payload;
    const result = await SiteApi.saveUserLocation(user.access_token, latitude, longitude);
    return result.data;
});

const getApiStatusRequest = createAsyncThunk("users/status", async (payload) => {
    const result = await SiteApi.getApiStatus();
    return result.data;
});

const subscribeToBankRequest = createAsyncThunk("users/bank/subscribe", async (payload) => {
    const {user, bankId, email} = payload;
    const result = await SiteApi.subscribeToBank(user.access_token, bankId, email);
    return result.data;
});

const requestDisbursementRequest = createAsyncThunk("users/disbursement", async (payload) => {
    const {user, firstName, lastName, accountNumber, swiftCode} = payload;
    const result = await SiteApi.requestDisbursement(user.access_token, firstName, lastName, accountNumber, swiftCode);
    return result.data;
});

const sendContactRequest = createAsyncThunk("users/contact", async (payload) => {
    const {user, email, name, message} = payload;
    const result = await SiteApi.userContact(user.access_token, email, name, message);
    return result.data;
});

const userStore = createSlice({
    name: "user",
    initialState: {
        regions: null,
        userProfile: null,
        userFriends: null,
        userSettings: null
    },
    reducers: {
        updateSettings: (state, action) => {
            state.userSettings = null;
        },
        logout: (state, action) => {
            state.userProfile = null;
            state.userFriends = null;
            state.userSettings = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRegionsRequest.fulfilled, (state, action) => {
            console.log("Get regions result: ",action.payload);
            state.regions = action.payload;
        });
        builder.addCase(getUserProfileRequest.fulfilled, (state, action) => {
            console.log("Get profile result: ",action.payload);
            state.userProfile = action.payload;
        });
        builder.addCase(getUseFriendsRequest.fulfilled, (state, action) => {
            console.log("Get user friends result: ",action.payload);
            state.userFriends = action.payload;
        });
        builder.addCase(getUserSettingsRequest.fulfilled, (state, action) => {
            console.log("Get user settings result: ",action.payload);
            state.userSettings = action.payload;
        });
        builder.addCase(saveUserSettingsRequest.fulfilled, (state, action) => {
            console.log("Save user settings result: ",action.payload);
        });
        builder.addCase(deleteAccountRequest.fulfilled, (state, action) => {
            console.log("Delete account result: ",action.payload);
        });
        builder.addCase(saveUserLocationRequest.fulfilled, (state, action) => {
            console.log("Save user location result: ",action.payload);
        });
        builder.addCase(getApiStatusRequest.fulfilled, (state, action) => {
            console.log("API status result: ",action.payload);
        });
    }
});

const userReducerConfig = {
    key: 'userStoreReducer',
    storage: storage,
    blacklist: ['regions', 'userProfile', 'userFriends', 'userSettings']
};

const userStoreReducer = persistReducer(userReducerConfig, userStore.reducer);

export {
    userStore, 
    userStoreReducer, 
    getRegionsRequest, 
    getUserProfileRequest, 
    getUseFriendsRequest, 
    getUserSettingsRequest, 
    saveUserSettingsRequest, 
    changePasswordRequest,
    deleteAccountRequest,
    saveUserLocationRequest,
    getApiStatusRequest,
    subscribeToBankRequest,
    requestDisbursementRequest,
    sendContactRequest
}
