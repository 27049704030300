import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { commonReducer } from '../reducer/CommonReducer';
import { loginStoreReducer } from '../ui/auth/store/LoginStore';
import { partnerStoreReducer } from '../ui/partners/store/PartnerStore';
import { bankStoreReducer } from '../ui/banks/store/BankStore';
import { consentStoreReducer } from '../ui/consents/store/ConsentStore';
import { getRegionsRequest, userStoreReducer } from '../ui/user/store/UserStore';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import { transactionStoreReducer } from '../ui/transactions/store/TransactionStore';

const combinedReducer = combineReducers({
  commonReducer: commonReducer, 
  loginStoreReducer, 
  partnerStoreReducer: partnerStoreReducer, 
  bankStoreReducer: bankStoreReducer, 
  consentStoreReducer: consentStoreReducer,
  userStoreReducer: userStoreReducer,
  transactionStoreReducer: transactionStoreReducer
});
const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: ['commonReducer', 'bankStoreReducer', 'consentStoreReducer', 'partnerStoreReducer', 'userStoreReducer', 'transactionStoreReducer'],
    debug: true
  }  
const persistedReducer = persistReducer(persistConfig, combinedReducer);

const rootStore = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
let persistor = persistStore(rootStore);
export {
    rootStore,
    persistor
}