import * as React from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { Modal, Dialog } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from '../../reducer/CommonReducer';
import { loginRequest, loginStore, loginWithFacebookRequest } from './store/LoginStore';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

const LoginDialog = () => {
    const { t, i18n } = useTranslation(['auth', 'translation']);
    const showModal = useSelector((state) => state.commonReducer.showLoginForm);
    const dispatcher = useDispatch();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().max(255).required(t('email_is_required')).email(t('must_be_valid_email')),
            password: Yup.string().max(255).required(t('password_is_required')),
        }),
        onSubmit: (values) => {
            dispatcher(loginStore.actions.storeEmail(values.email));
            dispatcher(loginRequest({ loginEmail: values.email, loginPassword: values.password }))
                .unwrap()
                .then(
                    (res) => {
                        if (res.success) {
                            dispatcher(commonActions.notify({ type: 'success', message: 'logged_in' }));
                        } else {
                            dispatcher(commonActions.notify({ type: 'error', message: 'bad_auth' }));
                        }
                    },
                    (e) => {
                        dispatcher(commonActions.notify({ type: 'error', message: 'bad_auth' }));
                    }
                );
        },
    });

    const showRegister = () => {
        dispatcher(commonActions.showRegister(true));
    };
    const onFacebookLoginSuccess = (res) => {
        dispatcher(loginWithFacebookRequest(res.accessToken))
            .unwrap()
            .then(
                (res) => {
                    if (res.success) {
                        dispatcher(commonActions.showLogin(false));
                        dispatcher(commonActions.notify({ type: 'success', message: 'logged_in' }));
                    }
                },
                (err) => {
                    dispatcher(commonActions.notify({ type: 'error', message: 'bad_auth' }));
                }
            );
    };
    const onFacebookLoginError = (res) => {
        if (res && res.status == 'loginCancelled') {
            return;
        }
        dispatcher(commonActions.notify({ type: 'error', message: 'bad_auth' }));
    };
    const onFacebookProfileSucces = (res) => {
        if (res && !res.email) {
            dispatcher(commonActions.notify({ type: 'error', message: 'facebook_email_was_not_shared' }));
        }
        if (res && res.email) {
            dispatcher(loginStore.actions.storeEmail(res.email));
        }
    };
    const onForgetPassword = () => {
        dispatcher(commonActions.showLogin(false));
        dispatcher(commonActions.showForgetPassword(true));
    };
    const hideModal = () => {
        dispatcher(commonActions.showLogin(false));
    };
    return (
        <Modal open={showModal}>
            <div className='login__box'>
                ,
                <Link to='/'>
                    <img src='recash-temp-logo.webp' alt='logo' className='nav__logo' />
                </Link>
                <h2 id='basic-modal-dialog-title'>Log-in</h2>
                <Typography
                    id='basic-modal-dialog-description'
                    sx={{ mt: { xs: 1, sm: 3 }, mb: { xs: 2, sm: 4 } }}
                    color='text.tertiary'
                    className='login__text'
                >
                    Please enter your e-mail and password to log in.{' '}
                </Typography>
                <form onSubmit={formik.handleSubmit} className='login__form'>
                    <TextField
                        label='E-mail'
                        id='email'
                        name='email'
                        type='email'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={!!formik.errors.email}
                        helperText={formik.errors.email}
                        color='primary'
                        className='login__input'
                        sx={{ mb: { xs: 1, sm: 4 } }}
                    />
                    <div className='password'>
                        <TextField
                            label='Password'
                            id='password'
                            name='password'
                            type='password'
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            error={!!formik.errors.password}
                            helperText={formik.errors.password}
                            color='primary'
                            className='login__input'
                            sx={{ mb: 1 }}
                        />
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: '5px', mb: 2 }}>
                            <p className='text--small login__light-text'>Forget your password?</p>
                            <button className='btn btn--text  text--small' onClick={onForgetPassword}>
                                Generate new password
                            </button>
                        </Box>
                    </div>
                    <Box sx={{ mb: { xs: 2, sm: 4 }, mt: { xs: 1, sm: 2 } }}>
                        <Button variant='contained' type='submit'>
                            Login
                        </Button>
                    </Box>
                    {/* <Button variant='contained' onClick={hideModal}>
                            Cancel
                        </Button> */}
                    <Typography className='login__lines' sx={{ mb: { xs: 1, sm: 3 } }}>
                        Or log-in with
                    </Typography>
                    <Box sx={{ mb: { xs: 2, sm: 6 } }}>
                        <FacebookLogin
                            appId='2418718958175709'
                            onSuccess={onFacebookLoginSuccess}
                            onFail={onFacebookLoginError}
                            onProfileSuccess={onFacebookProfileSucces}
                            className='btn btn--mui-outline-rounded'
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: '5px' }}>
                        <p className='login__light-text text--small'>Don’t have an acocunt yet?</p>
                        <button variant='text' onClick={showRegister} className='btn btn--text text--small'>
                            Create an account here
                        </button>
                    </Box>

                    <CloseIcon onClick={hideModal} className='btn--close' />
                </form>
            </div>
        </Modal>
    );
};

export default LoginDialog;
