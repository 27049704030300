import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: 'auto',
    overFlowX: 'scroll',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
};

export default({model, show, data, onDeleteHandler, onCancelHandler}) => {
    const { t, i18n } = useTranslation('consents');
    return (
        <Modal open={show} aria-labelledby="modal-modal-title" aria-describedby="Consent details">
            <Box sx={style}>
                <Stack spacing={2} direction="column">
                    <Typography gutterBottom variant="h5" component="div">{data?.bank?.name}</Typography>
                    <Typography>{model.formatDate(data?.dateAdded)}</Typography>
                    <Typography>{model.formatConsentStatus(t, data?.status)}</Typography>
                    <Button variant="contained" color="error" onClick={onDeleteHandler}>Delete</Button>
                    <Button variant="contained" onClick={onCancelHandler}>Close</Button>
                </Stack>
            </Box>
        </Modal>
    )
}