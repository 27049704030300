import { Box, Typography, Stack, Button } from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export default({seePartnersHandler}) => {
    return(
        <Box sx={{m: '24px'}}>
            <Typography variant="h5">Step 3</Typography>
            <Stack direction="row" alignItems="center" gap={1} sx={{marginTop: '12px'}}>
                <CheckBoxIcon color="success" fontSize="large" />
                <Typography variant="body1">Success! Your consent was successfully added and you're ready to receive cashbacks!</Typography>
            </Stack>
            <Button variant="contained" sx={{marginTop: '12px'}} onClick={() => seePartnersHandler?.()}>See Partners</Button>
        </Box>
    );
}