import { Box, Typography, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import BasicAvatarTitle from '../../common/BasicAvatarTitle';

import 'swiper/css';
import HomeCouponCarouselCard from './HomeCouponCarouselCard';

export default ({ model }) => {
    const { t, i18n } = useTranslation('home');
    const user = model.getUser();
    let { couponNum, couponData } = model.getDefaultSortedCouponList();
    return (
        <div className='coupon__box'>
            {/* <BasicAvatarTitle title={t('cashback_coupons')} titleNum={couponNum} /> */}
            <Typography sx={{ color: 'white', mb: 1, letterSpacing: '-1px' }} variant='h4'>
                {t('cashback_coupons')}
            </Typography>
            {user.exists() && couponData.length > 0 && (
                <Swiper spaceBetween={20} slidesPerView={'auto'}>
                    {couponData.map((_, index) => (
                        <SwiperSlide key={_.id} className='slide'>
                            <img src='recash-temp-logo.webp' alt='logo' className='coupon__icon' />

                            <HomeCouponCarouselCard model={model} item={_} clickHandler={() => model.onCashbackCouponClicked(_)} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
            {user.exists() && couponData.length == 0 && <p>{t('no_cashback_coupons')}</p>}
            {!user.exists() && <p>{t('please_log_in_coupons')}</p>}
        </div>
    );
};
