import { useDispatch, useSelector } from "react-redux";
import LoginNeededComponent from "../common/LoginNeededComponent";
import CommonViewModel from "../common/model/CommonViewModel";
import TransactionListViewModel from "./model/TransactionListViewModel";
import UserModel from "../../model/UserModel";
import { useEffect } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState } from "react";
import CashbackItemComponent from "./ui/CashbackItemComponent";
import { Button } from "@mui/material";
import BasicDialog from "../common/BasicDialog";
import { useTranslation } from "react-i18next";
import RequestDisbursementDialog from "./ui/RequestDisbursementDialog";
import { commonActions } from "../../reducer/CommonReducer";
import TabPanel from "../common/TabPanel";

export default() => {
    const { t, i18n } = useTranslation('transactions');
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const dispatcher = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogText, setDialogText] = useState("");
    const [showRequestDisbursement, setShowRequestDisbursement] = useState(false);

    const commonModel = new CommonViewModel();
    const userData = useSelector(state => commonModel.getUser(state));
    const user = new UserModel(userData);
    const transactionData = useSelector(state => commonModel.getTransactionsData(state));
    const model = new TransactionListViewModel(transactionData, user);
    useEffect(() => model.getData(dispatcher));
    if(!userData) {
        return(<LoginNeededComponent />);
    }
    let cashbackList = [];
    switch(value) {
        case 0: cashbackList = model.getDefaultList(); break;
        case 1: cashbackList = model.getPendingList(); break;
        case 2: cashbackList = model.getPayableList(); break;
    }
    const onRequestPayout = () => {
        const canRequestDisbursement = model.canRequestDisbursement();
        if(canRequestDisbursement) {
            setShowRequestDisbursement(true);
            return;
        }
        //if the user cannot request a disbursement and has an active request show an error message
        const activeDisbursement = model.getActiveDisbursement();
        if(activeDisbursement) {
            setDialogTitle(t('active_disbursement_request_error_title'));
            setDialogText(t('active_disbursement_request_error_desc', {payoutRequestDate: model.getActiveDisbursementRequestedDate()}));
            setShowDialog(true);
            return;
        }
        //Otherwise if the user cannot request a disbursement and has no active request he is under the payout limit
        setDialogTitle(t('paylout_limit_error_title'));
        setDialogText(t('paylout_limit_error_desc', {payoutLimit: model.getRequestLimit(), payoutDays: model.getPayoutPendingDays()}));
        setShowDialog(true);
    }
    const onCloseDialog = () => {
        setShowDialog(false);
    }
    const onCloseRequestDisbursementDialog = () => {
        setShowRequestDisbursement(false);
    }

    const onSendDisbursementRequest = (payload) => {
        const {firstName, lastName, accountNumber, swiftCode} = payload;
        model.sendDisbursementRequest(dispatcher, firstName, lastName, accountNumber, swiftCode).unwrap().then(result => {
            if(result.success) {
                setShowRequestDisbursement(false);
                dispatcher(commonActions.notify({type: 'success', message: 'disbursement_saved'}));
                reloadFullPage(); //Reload so balance etc. is in sync
            }
            else {
                dispatcher(commonActions.notify({type: 'success', message: 'disbursement_error'}));
            }
        });
    }

    const reloadFullPage = () => {
        //TODO replace this with useEffect...
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

    return(
        <Box>
            <h4>Cashbacks</h4>
            <Box sx={{ width: '100%' }}>
                <Button variant="contained" onClick={onRequestPayout}>Request payout</Button>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: 2 }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab id="simple-tab-0" label="All" aria-controls="simple-tabpanel-0" />
                    <Tab id="simple-tab-1" label="Pending" aria-controls="simple-tabpanel-1" />
                    <Tab id="simple-tab-2" label="Payable" aria-controls="simple-tabpanel-2" />
                </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    {value == 0 && cashbackList.length > 0 && 
                        cashbackList.map(i => {
                            return <CashbackItemComponent model={model} key={'cashback_item_'+i.id} item={i} />
                        })
                    }
                    {value == 0 && cashbackList.length == 0 && 
                        <p>No Transactions</p>
                    } 
                </TabPanel>
                <TabPanel value={value} index={1}>
                    {value == 1 && cashbackList.length > 0 && 
                        cashbackList.map(i => {
                            return <CashbackItemComponent model={model} key={'cashback_item_'+i.id} item={i} />
                        })
                    }
                    {value == 1 && cashbackList.length == 0 && 
                        <p>No Transactions</p>
                    } 
                </TabPanel>
                <TabPanel value={value} index={2}>
                    {value == 2 && cashbackList.length > 0 && 
                        cashbackList.map(i => {
                            return <CashbackItemComponent model={model} key={'cashback_item_'+i.id} item={i} />
                        })
                    }
                    {value == 2 && cashbackList.length == 0 && 
                        <p>No Transactions</p>
                    } 
                </TabPanel>
            </Box>
            <BasicDialog show={showDialog} title={dialogTitle} text={dialogText} clickHandler={onCloseDialog} />
            <RequestDisbursementDialog show={showRequestDisbursement} onSendDisbursementRequest={onSendDisbursementRequest} onCloseHandler={onCloseRequestDisbursementDialog} />
        </Box>
    );
}