class PromoLinkData {

    constructor(d) {
        return Object.assign(this, d);
    }

    isTypeInternalPartner() {
        return (this.linkType == "INTERNAL_PARTNER");
    }

    isTypeInternalDialog() {
        return (this.linkType == "INTERNAL_DIALOG");
    }

    isTypeInternalBank() {
        return (this.linkType == "INTERNAL_BANK");
    }

    isTypeExternal() {
        return (this.linkType == "EXTERNAL");
    }

    getExternalLinkText(lang) {
        if(lang == "hu") {
            return this.linkText?.hu;
        }
        return this.linkText?.en;
    }

}

export default PromoLinkData;