import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import CommonViewModel from '../../../common/model/CommonViewModel';
import PartnerListViewModel from '../../model/PartnerListViewModel';
import UserModel from '../../../../model/UserModel';
import { Box, Button, FormControlLabel, FormGroup, Link, Modal, Stack, Switch } from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Autoplay } from "swiper";
import PartnerDetailViewModel from './model/PartnerDetailViewModel';
import ConditionItem from './ConditionItem';
import { useState } from 'react';

import LanguageIcon from '@mui/icons-material/Language';
import CouponCarouselCard from '../../../coupons/ui/CouponCarouselCard';
import { partnerStore } from '../../store/PartnerStore';
import PartnerMapComponent from './PartnerMapComponent';

export default() => {
    const [loaded, setLoaded] = useState(false);
    const [checked, setChecked] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const dispatcher = useDispatch();
    const navigation = useNavigate();
    const pathName = useLocation();
    //Data
    const commonModel = new CommonViewModel();
    const userData = useSelector(state => commonModel.getUser(state));
    const user = new UserModel(userData);
    const partnerData = useSelector(state => commonModel.getPartnerData(state));
    const forceReload = useSelector(state => commonModel.getForceReload(state));
    const listModel = new PartnerListViewModel(partnerData, user);
    useEffect(() => listModel.getData(dispatcher, forceReload));
    const selectedPartner = listModel.getPartnerById(listModel.getPartnerIdFromPath(pathName.pathname));
    if(selectedPartner && !loaded) {
        setLoaded(true);
        setChecked(selectedPartner.favorite);
    }
    const couponsForPartner = listModel.findCouponForPartner(selectedPartner?.id);
    const model = new PartnerDetailViewModel(selectedPartner, user);
    const conditionList = model.getConditionList();
    const favoritePartner = (event) => {
        model.favoritePartner(dispatcher, selectedPartner.id);
        setChecked(!checked);
    }
    const couponClicked = (id) => {
        dispatcher(partnerStore.actions.showCoupon(id));
    }
    const onShowMap = () => {
        if(loaded && selectedPartner) {
            setShowMap(true);
        }
    }
    const hideMap = () => {
        setShowMap(false);
    }
    if(!selectedPartner) {
        return "";
    }
    console.log(selectedPartner);
    return(
        <Box>
            {selectedPartner?.images.length > 0 && 
            <Swiper 
                spaceBetween={0} 
                loop={true} 
                navigation={false} 
                modules={[Navigation, Autoplay]} 
                slidesPerView={5} 
                autoplay={{delay: 2000, disableOnInteraction: false}}>
            {
                selectedPartner?.images.map((i, j) => {
                    return(
                        <SwiperSlide key={'partner_image_'+j}>
                            <img 
                                style={{objectFit: 'contain', width: '100%', maxHeight: '250px'}} 
                                src={listModel.getImageUrl(i)} />
                        </SwiperSlide>
                    )
                })
            }
            </Swiper>
            }
            <Stack sx={{display: 'inline-block', position: 'absolute', right: 0, marginRight: '16px', marginTop: '16px'}}>
                {user?.exists() && 
                    <FormGroup style={{display: 'inline-block'}}>
                        <FormControlLabel control={<Switch checked={checked} onChange={favoritePartner} />} label="Favorite" />
                    </FormGroup>
                }
                <Link href={selectedPartner?.website} target="_blank" >
                    <LanguageIcon />
                </Link>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center" sx={{marginTop: 2}}>
                <img src={selectedPartner?.getLogo(listModel)} style={{width: '80px'}} />
                <h4>{selectedPartner?.companyName}</h4>
            </Stack>
            <p>{selectedPartner?.description}</p>
            {user?.exists() && <h5>Coupons</h5>}
            {user?.exists() && couponsForPartner && 
                couponsForPartner.map(i => {
                    return <CouponCarouselCard model={model} key={'coupon_item'+i.id} item={i} clickHandler={couponClicked} />
                })
            }
            <h5>Conditions</h5>
            <Stack sx={{m: 2}} spacing={2}>
            {
                conditionList.map((i, j) => {
                    return <ConditionItem key={j} item={i} />
                })
            }
            <h5>Shops</h5>
            <Button variant="contained" onClick={onShowMap} sx={{width: '130px'}}>Open map</Button>
            {selectedPartner && selectedPartner.webshopUrl && 
            <Box>
                <h5>Webshop</h5>
                <Link href={selectedPartner?.webshopUrl} target="_blank" >
                    <Button variant="contained" sx={{width: '130px'}}>Webshop</Button>
                </Link>
            </Box>
            }
            </Stack>
            <Modal open={showMap}>
                <Box sx={{p: '16px', position: 'absolute', top: '50%', left: '50%', background:'white', transform: 'translate(-50%,-50%)', width: '80%', height: '80%'}}>
                    <Box sx={{height: '10%'}}>
                        <div>Shops</div>
                        <IconButton sx={{position: 'absolute', top: 0, right: 0}} onClick={hideMap}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{height: '90%'}}>
                        <PartnerMapComponent model={model} />
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
}