import * as React from 'react';
import { Box, Button, Modal, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: 'auto',
    overFlowX: 'scroll',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
};

export default({show, onSendDisbursementRequest, onCloseHandler}) => {
    const { t, i18n } = useTranslation('transactions');

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            accountNumber: '',
            swiftCode: ''
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .max(255)
                .required(t('first_name_is_required')),
            lastName: Yup.string()
                .max(255)
                .required(t('last_name_is_required')),
            accountNumber: Yup.string()
                .required(t('account_number_is_required'))
                .test('isValidAccountNumber', t('account_number_not_valid'), val => {
                    return val && val.match(/^\d+$/) && (val.length == 16 || val.length == 24);
                }),
            swiftCode: Yup.string().max(255)
        }),
        onSubmit: values => {
            const {firstName, lastName, accountNumber, swiftCode} = values;
            onSendDisbursementRequest?.({firstName, lastName, accountNumber, swiftCode});
        },
      });

    return(
        <Modal open={show}>
            <Box sx={style}>
                <Typography variant="h5" gutterBottom>Request a payout</Typography>
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <TextField 
                            label="First name" 
                            id="firstName"
                            name="firstName"
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                            error={!!formik.errors.firstName} 
                            helperText={formik.errors.firstName}
                            color="primary" />
                        <TextField 
                            label="Last name" 
                            id="lastName"
                            name="lastName"
                            type="lastName"
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            error={!!formik.errors.lastName} 
                            helperText={formik.errors.lastName}
                            color="primary" />
                        <TextField 
                            label="Account number" 
                            id="accountNumber"
                            name="accountNumber"
                            type="accountNumber"
                            onChange={formik.handleChange}
                            value={formik.values.accountNumber}
                            error={!!formik.errors.accountNumber} 
                            helperText={formik.errors.accountNumber}
                            color="primary" />
                        <TextField 
                            label="Swift code" 
                            id="swiftCode"
                            name="swiftCode"
                            type="swiftCode"
                            onChange={formik.handleChange}
                            value={formik.values.swiftCode}
                            error={!!formik.errors.swiftCode} 
                            helperText={formik.errors.swiftCode}
                            color="primary" />
                        <Button variant="contained" type="submit">Save</Button>
                        <Button variant="contained" color="secondary" onClick={onCloseHandler}>Close</Button>
                    </Stack>
                </form>
            </Box>
        </Modal>
    );
}