import BaseViewModel from "../../../model/viewModel/BaseViewModel";
import { changePasswordRequest, getUserSettingsRequest, saveUserSettingsRequest } from "../store/UserStore";

class UserSettingsViewModel extends BaseViewModel {

    constructor(data, user) {
        super();
        this.data = data?.data;
        this.user = user;
    }

    getData(dispatcher) {
        if(!this.data && this.user) {
            dispatcher(getUserSettingsRequest(this.user));
        }
    }

    validateNewPassword(oldPassword, password, password2) {
        const errors = {};
        if(!oldPassword) {
            errors.oldPassword = this.getError('cant_be_empty');
        }
        if(!password) {
            errors.newPassword = this.getError('cant_be_empty');
        }
        if(password && password.length < 8) {
            errors.newPassword = this.getError('password_too_short');
        }
        if(password && password.length > 64) {
            errors.newPassword = this.getError('password_too_long');
        }
        if(!password2) {
            errors.newPassword2 = this.getError('cant_be_empty');
        }
        if(password2 && password2.length < 8) {
            errors.newPassword2 = this.getError('password_too_short');
        }
        if(password2 && password2.length > 64) {
            errors.newPassword2 = this.getError('password_too_long');
        }
        if(password && password2 && password != password2) {
            errors.newPassword2 = this.getError('passwords_dont_match');
        }
        return (Object.keys(errors).length > 0) ? errors : null;
    }

    saveNewPassword(dispatcher, oldPassword, newPassword) {
        if(!this.user) {
            return;
        }
        return dispatcher(changePasswordRequest({user: this.user, oldPassword, newPassword})).unwrap();
    }

    saveSettings(dispatcher, data) {
        if(!this.user) {
            return;
        }
        return dispatcher(saveUserSettingsRequest({user: this.user, data: data})).unwrap();
    }

    validateAccountNumber(number1, number2, number3) {
        const errors = {};
        if(!number1) {
            errors.accountNumberPart1 = this.getError('cant_be_empty');
        }
        if(!number2) {
            errors.accountNumberPart2 = this.getError('cant_be_empty');
        }
        if(number1 && !isFinite(number1)) {
            errors.accountNumberPart1 = this.getError('must_be_numeric');
        }
        if(number1 && isFinite(number1) && number1.length != 8) {
            errors.accountNumberPart1 = this.getError('must_be_8_long');
        }
        if(number2 && !isFinite(number2)) {
            errors.accountNumberPart2 = this.getError('must_be_numeric');
        }
        if(number2 && isFinite(number2) &&  number2.length != 8) {
            errors.accountNumberPart2 = this.getError('must_be_8_long');
        }
        if(number3 && !isFinite(number3)) {
            errors.accountNumberPart3 = this.getError('must_be_numeric');
        }
        if(number3 && isFinite(number3) &&  number3.length != 8) {
            errors.accountNumberPart3 = this.getError('must_be_8_long');
        }
        return (Object.keys(errors).length > 0) ? errors : null;
    }

    getError(message) {
        return {error: true, message: message};
    }

}

export default UserSettingsViewModel;