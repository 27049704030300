import { useTranslation } from "react-i18next";

export default({item}) => {
    const { t, i18n } = useTranslation('discounts');
    if(item.type == 'daily') {
        return(
            <p>{t(item.day)} - {item.text} - {item.percentage}</p>
        );
    }
    if(item.text) {
        return(
            <p>{t(item.type)} - {item.text}%</p>
        );
    }
    return(
        <p>{t(item.type)}</p>
    );
}