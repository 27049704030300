import PromoLinkData from "./PromoLinkData";

class PromoModel {

    constructor(d) {
        let it = Object.assign(this, d);
        it.promoLinkData = new PromoLinkData(it.promoLinkData);
        return it;
    }

    getKey() {
        return 'promo_item_'+this.id;
    }

    getImageUrl(viewModel) {
        return viewModel.getImageUrlFromId(this.imageId);
    }

}

export default PromoModel;