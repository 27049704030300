import { Provider, useDispatch } from 'react-redux';
import { rootStore, persistor } from './rootStore';
import { PersistGate } from 'redux-persist/integration/react';

export default (props) => {
    return (
        <Provider store={rootStore}>
            <PersistGate loading={null} persistor={persistor}>
                {props.children}
            </PersistGate>
        </Provider>
    );
}