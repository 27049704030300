import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        padding: '5px 20px',
                        borderRadius: '50vw',
                        border: '1px solid #0080FF',
                    },
                },
            ],
            defaultProps: {
                color: 'primary',
                variant: 'contained',
                style: {
                    padding: '8px 30px',
                    borderRadius: '50vw',
                },
            },
            styleOverrides: {
                root: {
                    borderRadius: '30px',
                },
            },
        },
        MuiLoadingButton: {
            defaultProps: {
                color: 'primary',
                type: 'submit',
                variant: 'contained',
            },
        },
    },
    palette: {
        white: {
            main: '#f1f1f1',
        },
        primary: {
            main: '#0080FF',
        },
    },
});
