import { Box, Typography, CircularProgress } from "@mui/material";

export default() => {
    return(
        <Box sx={{m: '24px'}}>
            <Typography variant="h5">Step 3</Typography>
            <Typography variant="body2" sx={{marginTop: '12px'}}>
                Please follow the steps on your banks page. Once you finished, you'll be redirected to our website. Once you see our logo, you can close the new window and will see a message here.
            </Typography>
            <CircularProgress sx={{marginTop: '24px'}} />
        </Box>
    );
}